import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';
import '../OmCaseNote.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ArrayStore from 'devextreme/data/array_store';

import { Form, SimpleItem, EmptyItem, Label, ButtonItem, ButtonOptions, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';

const CaseNote = props => {
  const { user, caseNoteData, reloadInquiryData, showDocuments, showChat, showDocSection, showChatSection } = props;
  const [lpInquiryState, setLpInquiryState] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchInquiryState = async () => {
      const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/47', 'GET', null, {
        Authorization: 'Bearer ' + user.token,
      });
      setLpInquiryState(dataArray);
    };

    fetchInquiryState();
  }, [sendRequest]);

  const stInquiryState = new ArrayStore({
    key: 'lp_id',
    data: lpInquiryState,
  });

  const loadedInquiryData = {
    entryid: caseNoteData && parseInt(caseNoteData.entryid),
    subject: caseNoteData && caseNoteData.subject,
    case_content: caseNoteData && caseNoteData.case_content,
  };

  const createDocRequest = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/createdocrequest',
        'POST',
        JSON.stringify({
          entryid: caseNoteData.entryid,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      reloadInquiryData(true);
    } catch (err) {
      console.log(err);
    }
  };

  const createChat = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/createchat',
        'POST',
        JSON.stringify({
          entryid: caseNoteData.entryid,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      reloadInquiryData(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div id='case-note-from'>
        <table>
          <tbody>
            <tr>
              <td width='60px'>Case</td>
              <td width='100px'>
                <b>{caseNoteData.from_sender}</b>
              </td>
              <td width='60px'>User:</td>
              <td width='300px'>
                <b>{caseNoteData.portaluser}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Form id='note-req-form' formData={loadedInquiryData} labelLocation={'left'} colCountByScreen={colCountByScreen}>
        <Item itemType='group' colCount={1}>
          <SimpleItem dataField='subject' editorOptions={{ stylingMode: 'outlined' }}>
            <Label text='Subject' visible={true} />
          </SimpleItem>
          <SimpleItem
            dataField='case_content'
            editorType='dxTextArea'
            editorOptions={{
              height: 200,
              stylingMode: 'outlined',
              readOnly: false,
            }}
          >
            <Label text='Content' visible={true} />
          </SimpleItem>
        </Item>
        <EmptyItem />
        <Item>
          <ResponsiveBox id='doc-chat-btn'>
            <Row ratio={1} />
            <Col ratio={1} />
            <Col ratio={8} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <CheckBox
                text='Documents'
                value={showDocSection}
                disabled={showDocSection}
                onValueChanged={e => {
                  if (e.value === true) {
                    createDocRequest();
                  }
                  showDocuments();
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <CheckBox
                text='Chat'
                value={showChatSection}
                disabled={showChatSection}
                onValueChanged={e => {
                  if (e.value === true) {
                    createChat();
                  }
                  showChat();
                }}
              />
            </Item>
          </ResponsiveBox>
        </Item>
      </Form>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CaseNote;
