import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, Item, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import './ContactEditPop.css';

const ContactEditOther = props => {
  const { showPopup, contactid, modeInsert, user, getContactData } = props;
  const otherDataFormRef = useRef(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selContact, setSelContact] = useState([]);
  const [selCompanyEmployee, setSelCompanyEmployee] = useState({});
  const [reloadData, setReloadData] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);

  useEffect(() => {
    const fetchSelContact = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/singlecontact/${contactid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setSelContact(dataArray[0]);
      } catch (err) {}
    };

    if (showPopup) {
      if (contactid && contactid > 0 && !modeInsert) {
        fetchSelContact();
      } else {
        setSelContact({});
      }
    }
  }, [contactid, reloadData, showPopup]);

  const contactData = {
    operation: modeInsert === true ? 1 : 2,
    contactid: selContact.contactid ? selContact.contactid : -1,
    companyid: selContact.companyid ? selContact.companyid : -1,
    contact_typeid: selContact.contact_typeid ? selContact.contact_typeid : 1,
    contact_userid: selContact.contact_userid ? selContact.contact_userid : user.userId,
    portalid: selContact.portalid ? selContact.portalid : user.portalid,
    lastname: selContact.lastname ? selContact.lastname : '',
    firstname: selContact.firstname ? selContact.firstname : '',
    salutation: selContact.salutation ? selContact.salutation : '',
    email: selContact.email ? selContact.email : '',
    phone_one: selContact.phone_one ? selContact.phone_one : '',
    contact_cat: selContact.contact_cat ? selContact.contact_cat : 85,
    jobtitle: selContact.jobtitle ? selContact.jobtitle : '',
    addressfield: selContact.addressfield ? selContact.addressfield : '',
  };

  return (
    <div id='contact-edit'>
      <Form
        id={'form'}
        ref={otherDataFormRef}
        formData={contactData}
        validationGroup='contact'
        labelLocation={'left'}
        onFieldDataChanged={e => {
          if (otherDataFormRef.current) {
            console.log(5, 1, contactData);
            getContactData(contactData);
          }
        }}
      >
        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='lastname' colSpan={4}>
            <Label text='Last Name' />
          </SimpleItem>
        </Item>

        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='firstname' colSpan={3}>
            <Label text='First Name' />
          </SimpleItem>
          <SimpleItem dataField='salutation' colSpan={1}>
            <Label text=' ' />
          </SimpleItem>
        </Item>

        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='email' colSpan={3} />
        </Item>
        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='phone_one' colSpan={3}>
            <Label text='Phone' />
          </SimpleItem>
        </Item>

        <EmptyItem />

        <Item itemType='group' colCount={5}>
          <SimpleItem
            dataField='addressfield'
            colSpan={3}
            editorType='dxTextArea'
            editorOptions={{ height: 150, stylingMode: 'outlined' }}
            autoFocus
          >
            <Label text='Address' />
          </SimpleItem>
        </Item>

        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='jobtitle' colSpan={4}>
            <Label text='Commet' />
          </SimpleItem>
        </Item>
      </Form>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ContactEditOther;
