import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ArrayStore from 'devextreme/data/array_store';
import Button from 'devextreme-react/button';
import { useAuth } from '../../contexts/auth';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import DataGrid, { Column } from 'devextreme-react/data-grid';

import PortalUserPop from './PortalUserPop';

import moment from 'moment';

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

const UserTab = props => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const userDataGrid = useRef(null);
  const [userLoadedData, setUserLoadedData] = useState();
  const [userDataList, setUserDatalist] = useState([]);
  const [selUserId, setSelUserId] = useState();
  const [showUserEditPopup, setShowUserEditPopup] = useState(false);
  const [popModeInsert, setpopModeInsert] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/user/portaluser', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setUserDatalist(dataArray);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, reloadUsers]);

  const stuserData = new ArrayStore({
    key: 'id',
    data: userDataList,
  });

  const cellStatusValue = data => {
    if (data.active && data.activated_at) {
      let formattedDate = moment(data.activated_at).format('DD.MM.YYYY');
      return `Activated ${formattedDate}`;
    }

    if (!data.active && data.deactivated_at) {
      let formattedDate = moment(data.deactivated_at).format('DD.MM.YYYY');
      return `Deactivated ${formattedDate}`;
    } else return `Deactivated`;
  };

  const openUserEditPage = () => {
    setpopModeInsert(false);
    setShowUserEditPopup(true);
    //  history.push({
    //    pathname: '/useredit',
    //    user: {
    //      operation: 2,
    //      userid: selUserId,
    //    },
    //  });
  };

  const openNewUserPage = () => {
    setpopModeInsert(true);
    setShowUserEditPopup(true);
    //  history.push({
    //    pathname: '/useredit',
    //    user: {
    //      operation: 1,
    //      userid: -1,
    //    },
    //  });
  };

  const hideUserEditPopup = useCallback(() => {
    setShowUserEditPopup(false);
    setReloadUsers(!reloadUsers);
  });

  return (
    <React.Fragment>
      <PortalUserPop
        showPopup={showUserEditPopup}
        hidePopup={hideUserEditPopup}
        user={user}
        selUserId={selUserId}
        modeInsert={popModeInsert}
      />
      <div id='portal-data-grid-box'>
        <ResponsiveBox>
          <Row ratio={1} />
          <Col ratio={1.5} />
          <Col ratio={8} />
          <Col ratio={1} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='puserbutton'>
              <Button
                width={100}
                height={50}
                text='Add'
                type='default'
                stylingMode='contained'
                onClick={openNewUserPage}
              />
              <div id='puserbutton-2'>
                <Button
                  width={100}
                  height={50}
                  text='Open'
                  type='success'
                  stylingMode='contained'
                  onClick={openUserEditPage}
                />
              </div>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <DataGrid
              id='portalusersGrid'
              ref={userDataGrid}
              dataSource={stuserData}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              columnAutoWidth={false}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              onFocusedRowChanged={e => {
                setSelUserId(e.row.key);
              }}
            >
              <Column dataField='id' visible={false}></Column>
              <Column dataField='portalname' caption='Portal' width={120}></Column>
              <Column dataField='email' caption='email' width={180}></Column>
              <Column dataField='last_name' caption='Name' width={120}></Column>
              <Column dataField='first_name' caption='First Name' width={120}></Column>
              <Column dataField='rolename' caption='User Role' width={120}></Column>
              <Column caption='Status' calculateCellValue={cellStatusValue} width={180} />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

export default UserTab;
