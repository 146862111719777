import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import Button from "devextreme-react/button";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
} from "devextreme-react/form";

import ContactEditPop from "./ContactEditPop";
import ContactTypePop from "./ContactTypePop";

const getscreenheight = () => {
  const anValue = window.innerHeight - 670;
  // console.log(5678, anValue);
  return anValue;
};

const ClientsApprovedOneTab = (props) => {
  const { passToParent, user } = props;
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [contactsDataList, setContactsDatalist] = useState([]);
  const [reloadContacts, setReloadContacts] = useState(false);

  const [selectedContactid, setSelectedContactid] = useState(-1);
  const [selContactRow, setSelContactRow] = useState({});

  const [inApprovalCheckedId, setInApprovalCheckedId] = useState(0);
  const [clientSearchText, setClientSearchText] = useState("");
  const [inSignupCheckedId, setInSignupCheckedId] = useState(0);

  const [showContactPopup, setShowContactPopup] = useState(false);
  const [popModeInsert, setPopModeInsert] = useState(false);

  const [showContactTypePopup, setShowContactTypePopup] = useState(false);
  const [selContType, setSelContType] = useState(3);

  const [selContactContType, setSelContactContType] = useState(3);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/contacts/contacts/${user.portalid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setContactsDatalist(dataArray);
      } catch (err) {}
    };

    if (clientSearchText === "") {
      fetchContacts();
    } else {
      doSearch(clientSearchText);
    }
  }, [sendRequest, reloadContacts, inApprovalCheckedId, inSignupCheckedId]);

  const doSearch = (anSearchText) => {
    let field1 = anSearchText.split(" ")[0] ? anSearchText.split(" ")[0] : "";
    let field2 = anSearchText.split(" ")[1] ? anSearchText.split(" ")[1] : "";
    let field3 = anSearchText.split(" ")[2] ? anSearchText.split(" ")[2] : "";
    let field4 = anSearchText.split(" ")[3] ? anSearchText.split(" ")[3] : "";
    let field5 = anSearchText.split(" ")[4] ? anSearchText.split(" ")[4] : "";
    let field6 = anSearchText.split(" ")[5] ? anSearchText.split(" ")[5] : "";

    field1 = cleanfield(field1);
    field2 = cleanfield(field2);
    field3 = cleanfield(field3);
    field4 = cleanfield(field4);
    field5 = cleanfield(field5);
    field6 = cleanfield(field6);

    fetchSearchContacts(field1, field2, field3, field4, field5, field6);
  };

  const buttonAttributes = {
    text: "Search",
    type: "normal",
    icon: "search",
    height: 36,
    useSubmitBehavior: true,
  };

  const fetchSearchContacts = async (
    field1,
    field2,
    field3,
    field4,
    field5,
    field6
  ) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/contacts/contactsallsearch",
        "POST",
        JSON.stringify({
          field1: "%" + field1 + "%",
          field2: "%" + field2 + "%",
          field3: "%" + field3 + "%",
          field4: "%" + field4 + "%",
          field5: "%" + field5 + "%",
          field6: "%" + field6 + "%",
          portalid: user.portalid,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      ).then((data) => {
        setContactsDatalist(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const cleanfield = (anfield) => {
    let resultfield = anfield.replace("+", "");
    resultfield = resultfield.replace("-", "");
    resultfield = resultfield.replace("(", "");
    resultfield = resultfield.replace(")", "");
    resultfield = resultfield.replace(";", "");
    resultfield = resultfield.replace(",", "");
    return resultfield;
  };

  const handleSubmit = (e) => {
    const searchtext = e.target.elements.searchtext.value;
    setClientSearchText(searchtext);
    doSearch(searchtext);
    e.preventDefault();
  };

  const hideContactPopup = (doContactReload) => {
    setShowContactPopup(false);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
    }
  };

  const AddContact = () => {
    setShowContactTypePopup(true);
  };

  const OpenContact = () => {
    setSelContType(selContactContType);
    setPopModeInsert(false);
    setShowContactPopup(true);
  };

  const hideContactTypePopup = (aContType) => {
    setShowContactTypePopup(false);

    setSelContType(aContType);

    if (aContType !== -1) {
      setSelectedContactid(-15);
      setPopModeInsert(true);
      setShowContactPopup(true);
    }
  };

  return (
    <React.Fragment>
      <ContactEditPop
        showPopup={showContactPopup}
        hidePopup={hideContactPopup}
        user={user}
        selcontactid={selectedContactid}
        modeInsert={popModeInsert}
        contactType={selContType}
      />
      <ContactTypePop
        showPopup={showContactTypePopup}
        hidePopup={hideContactTypePopup}
      />
      <ResponsiveBox>
        <Col ratio={1} />
        <Row ratio={1} />

        <Item>
          <Location screen="md lg sm xs" row={0} col={0} />
          <form
            action="search"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />
              <Col ratio={7} />
              <Item>
                <Location screen="md lg sm xs" row={0} col={0} />
                <div id="hm-btn-section-1">
                  <p>Contact</p>
                </div>
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={0} col={1} />
                <Form id="client-search-section" labelLocation="left">
                  <GroupItem cssClass="first-group" colCount={3}>
                    <GroupItem>
                      <SimpleItem dataField="searchtext">
                        <Label text="find" />
                      </SimpleItem>
                    </GroupItem>
                    <ButtonItem
                      horizontalAlignment="left"
                      buttonOptions={buttonAttributes}
                    />
                  </GroupItem>
                </Form>
              </Item>
            </ResponsiveBox>
          </form>
          <div>
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />
              <Col ratio={7} />
              <Item>
                <Location screen="md lg sm xs" row={0} col={0} />
                <Location screen="md lg sm xs" row={0} col={0} />

                <div id="hm-btn-section-1">
                  <Button
                    width={120}
                    height={36}
                    text="Add"
                    type="default"
                    onClick={AddContact}
                  />
                </div>
                <div id="hm-btn-section-11">
                  <Button
                    width={120}
                    height={36}
                    text="Open"
                    type="success"
                    onClick={OpenContact}
                  />
                </div>
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={0} col={1} />
                <DataGrid
                  id="contact-section-grid"
                  height={getscreenheight}
                  dataSource={contactsDataList}
                  keyExpr="contactid"
                  defaultFocusedRowIndex={0}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  focusedRowEnabled={true}
                  showBorders={true}
                  showColumnLines={true}
                  showRowLines={true}
                  autoNavigateToFocusedRow={true}
                  focusedRowKey={selectedContactid}
                  onFocusedRowChanged={(e) => {
                    if (e.row) {
                      setSelectedContactid(e.row.key);
                      passToParent(e.row.key);
                      if (e.row.data) {
                        setSelContactRow(e.row.data);
                        setSelContactContType(e.row.data.contact_typeid);
                      }
                    }
                  }}
                >
                  <Scrolling mode="virtual" />
                  <FilterRow visible={false} />
                  <Column dataField="clientid" visible={false}></Column>
                  <Column dataField="companyid" visible={false}></Column>
                  <Column dataField="contact_typeid" visible={false}></Column>
                  <Column dataField="lastname" caption="Name" width={180} />
                  <Column
                    dataField="firstname"
                    caption="First Name"
                    width={100}
                    visible={true}
                  />
                  <Column dataField="salutation" caption="" width={50} />
                  <Column
                    dataField="company_name"
                    caption="Company"
                    width={150}
                  />
                  <Column dataField="email" caption="E Mail" width={200} />
                  <Column dataField="phone_one" caption="Phone" width={200} />
                  <Column dataField="contact_type" caption="Type" width={100} />
                </DataGrid>
              </Item>
            </ResponsiveBox>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ClientsApprovedOneTab;
