import { withNavigationWatcher } from './contexts/navigation';
import {
  ContactsPage,
  PortalUserPage,
  PartnerDocuments,
  OperationsPage,
  InquiryPage,
  OmCaseNotePage,
  PortalsPage,
  MailTemplatesPage,
  OpTemplatesPage,
  LookupPage,
  CompanyCalendarPage,
  CommPage,
} from './pages';

const routes = [
  {
    path: '/contacts',
    component: ContactsPage,
  },
  {
    path: '/companycalendar',
    component: CompanyCalendarPage,
  },
  {
    path: '/portalusers',
    component: PortalUserPage,
  },
  {
    path: '/documents',
    component: PartnerDocuments,
  },
  { path: '/operations', component: OperationsPage },
  { path: '/inquiry', component: InquiryPage },
  { path: '/casenote', component: OmCaseNotePage },
  {
    path: '/portals',
    component: PortalsPage,
  },
  {
    path: '/portalusers',
    component: PortalUserPage,
  },
  {
    path: '/mailtemplates',
    component: MailTemplatesPage,
  },
  {
    path: '/optemplates',
    component: OpTemplatesPage,
  },
  {
    path: '/lookuppage',
    component: LookupPage,
  },
  {
    path: '/communicate',
    component: CommPage,
  },
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
