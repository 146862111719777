import React, { useEffect, useState } from 'react';
import Box, { Item } from 'devextreme-react/box';
import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import './UserPanel.css';

import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';

const AdminPanel = () => {
  const { user } = useAuth();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [usersDataList, setUsersDatalist] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/user/portalusers/${user.portalid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setUsersDatalist(dataArray);
      } catch (err) {}
    };

    user && fetchUsers();
  }, [sendRequest, user.portalid]);

  const UserCellClick = e => {
    user.comcell = {
      selUserid: e.row.data.userid,
    };
    history.push({
      pathname: '/communicate',
    });
  };

  return (
    <div>
      <DataGrid
        id='menu-user-grid'
        showBorders={true}
        dataSource={usersDataList}
        keyExpr='userid'
        focusedRowEnabled={true}
        showColumnHeaders={false}
        columnAutoWidth={true}
        onCellClick={e => {
         // UserCellClick(e);
        }}
      >
        <Column dataField='userid' visible={false}></Column>
        <Column dataField='username' caption='User' width='100%'></Column>
      </DataGrid>

      <div>
        <Button id='menuadminButton' width='92%' height='36' text='Call' type='normal' stylingMode='outlined'></Button>
      </div>
      <div>
        <Button
          id='menuadminButton'
          width='92%'
          height='36'
          text='Chat'
          type='normal'
          stylingMode='outlined'
        ></Button>
      </div>

    </div>
  );
};

export default AdminPanel;
