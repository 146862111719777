import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import { Button } from "devextreme-react/button";
import { Popup, ToolbarItem } from "devextreme-react/popup";

import {
  Form,
  SimpleItem,
  EmptyItem,
  ButtonItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";

import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid";

const CasesFavoritesPop = (props) => {
  const { showPopup, hidePopup, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const [casesListData, setCasesListData] = useState([]);
  const [caseDataModified, setCaseDataModified] = useState(false);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/all/${user.portalid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        // console.log(dataArray);
        setCasesListData(dataArray);
      } catch (err) {}
    };

    if (user.userId && showPopup) {
      fetchCases();
    }
  }, [sendRequest, user.userId, showPopup]);

  const openFavoritesDefinitonPopup = () => {};

  return (
    <React.Fragment>
      <Popup
        title={"Favorites"}
        width={1100}
        height={680}
        ref={popupRef}
        visible={showPopup}
        resizeEnabled={true}
        onHiding={() => {
          hidePopup(caseDataModified);
        }}
      >
        <ResponsiveBox>
          <Row ratio={10} />
          <Row ratio={0.1} />
          <Col />
          <Item>
            <Location screen="md lg sm xs" row={0} col={0} />
            <ResponsiveBox>
              <Row />
              <Col ratio={6} />
              <Col ratio={3} />
              <Col ratio={6} />
              <Item>
                <Location screen="md lg sm xs" row={0} col={0} />
                <DataGrid
                  dataSource={casesListData}
                  keyExpr="entryid"
                  height={500}
                  defaultFocusedRowIndex={0}
                  columnAutoWidth={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  focusedRowEnabled={true}
                  showBorders={true}
                  showRowLines={true}
                  showColumnLines={true}
                  autoNavigateToFocusedRow={true}
                >
                  <SearchPanel
                    visible={true}
                    width={400}
                    placeholder="Find..."
                  />
                  <Column dataField="entryid" visible={false} />
                  <Column
                    dataField="clientname"
                    caption="Contact"
                    width={200}
                  ></Column>
                  <Column dataField="subject" caption="Subject"></Column>
                </DataGrid>
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={0} col={1} />
                <div id="favorites-pop-btn-section">
                  <Button width={120} height={36} text="Add" />
                  <Button
                    id="btn-vert-dist"
                    width={120}
                    height={36}
                    text="Remove"
                  />
                </div>
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={0} col={2} />
                <Form labelLocation="left">
                  <GroupItem cssClass="first-group" colCount={12}>
                    <SimpleItem
                      colSpan={8}
                      dataField="userid"
                      editorType="dxSelectBox"
                      name="user_listbox"
                      editorOptions={{
                        // dataSource: caluser,
                        valueExpr: "userid",
                        displayExpr: "text",
                        searchEnabled: true,
                      }}
                    >
                      <Label text="Group" />
                    </SimpleItem>
                    <ButtonItem
                      colSpan={4}
                      horizontalAlignment="right"
                      buttonOptions={{
                        text: "Define",
                        type: "normal",
                        // icon: "search",
                        width: 110,
                        height: 36,
                        onClick: openFavoritesDefinitonPopup,
                      }}
                    />
                  </GroupItem>
                </Form>

                <DataGrid
                height={450}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                ></DataGrid>
              </Item>
            </ResponsiveBox>
          </Item>
          <Item>
            <Location screen="md lg sm xs" row={1} col={0} />
            <div id="closebutton">
              <div style={{ flexDirection: "row" }}>
                <Button
                  id="savebutton"
                  width={120}
                  height={40}
                  text="close"
                  type="normal"
                  stylingMode="contained"
                  onClick={() => {
                    hidePopup(false);
                  }}
                />
              </div>
            </div>
          </Item>
        </ResponsiveBox>
      </Popup>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CasesFavoritesPop;
