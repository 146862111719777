import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import { useAuth } from '../../contexts/auth';

import './side-navigation-menu.scss';

import * as events from 'devextreme/events';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { ConstantLine } from 'devextreme-react/chart';

import UserPanel from './UserPanel';

const SideNavigationMenu = props => {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props;

  const { user } = useAuth();

  const { isLarge } = useScreenSize();

  const generatePermissionNav = () => {
    const item = user.permissions;
    const subItems = user.permissions;

    const res = [];
    navigation.forEach(navItem => {
      for (let key in item) {
        if (navItem.hasOwnProperty(item[key].pshort)) {
          const tgtItem = navItem;

          if (navItem.hasOwnProperty('items')) {
            const itemsarray = [];

            navItem.items.forEach(navSubItem => {
              for (let subkey in subItems) {
                if (navSubItem.hasOwnProperty(subItems[subkey].pshort)) {
                  itemsarray.push(navSubItem);
                  break;
                }
              }
            });

            tgtItem.items = itemsarray;
          }

          res.push(tgtItem);

          break;
        }
      }
    });

    return res;
  };

  function normalizePath() {
    const permNav = generatePermissionNav(user.permissions);
    return permNav.map(item => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLarge };
    });
  }

  const items = useMemo(
    normalizePath,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    element => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, 'dxclick');
      }

      wrapperRef.current = element;
      events.on(element, 'dxclick', e => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (treeView) {
      if (currentPath !== undefined) {
        treeView.selectItem(currentPath);
        treeView.expandItem(currentPath);
      }

      if (compactMode) {
        treeView.collapseAll();
      }
    }
  }, [currentPath, compactMode]);

  return (
    <div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <ResponsiveBox>
        <Row ratio={1}/>
        <Col />
        <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <TreeView
              ref={treeViewRef}
              items={items}
              expandedExpr='isExpanded'
              keyExpr={'path'}
              selectionMode={'single'}
              focusStateEnabled={false}
              expandEvent={'click'}
              onItemClick={selectedItemChanged}
              onContentReady={onMenuReady}
              width={'100%'}
            />
        <div style={{ flexDirection: 'column' }}>
          <div id='user-panel'>
          <UserPanel />
          </div>
        </div>
        </Item>

        </ResponsiveBox>

      </div>
    </div>
  );
};

export default SideNavigationMenu;
