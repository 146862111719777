import HTTP from '../../../api/HTTP';

class InternalMessagingHelper {
  getThreads(clientId, queryStringData) {
    return HTTP.get(`/internal_messaging/threads?clientId=${clientId}`, {
      params: queryStringData,
    }).then(response => response.data);
  }

  getThreadDetails(theadId) {
    return HTTP.get(`/internal_messaging/threads/${theadId}`).then(response => response.data);
  }

  createThread(threadData) {
    return HTTP.post('/internal_messaging/threads', threadData).then(response => {
      return response.data;
    });
  }

  addThreadMessage(threadData) {
    return HTTP.post('/internal_messaging/messages', threadData).then(response => response.data);
  }

  getThreadMessages(threadId, queryStringData) {
    return HTTP.get(`/internal_messaging/messages/${threadId}`, {
      params: queryStringData,
    }).then(response => response.data);
  }

  getFileContent = documentId => {
    return HTTP.get(`/internal_messaging/files/${documentId}`).then(response => response.data);
  };
}

export default new InternalMessagingHelper();
