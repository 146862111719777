import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';
import './inquiries.css';

import { Item } from 'devextreme-react/responsive-box';
import { Popup } from 'devextreme-react/popup';
import { DocumentRequestFormData } from '../docexchange/forms';
import ServiceProviderHelper from '../../services/ServiceProviderHelper';
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { Button } from 'devextreme-react/button';

import { useInquiry } from '../../hooks/inquiry-hook';

function AddInquiry(props) {
  const { showPopup, hidePopup, selSignupClientId, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { createInquiry } = useInquiry();
  const [noteData, setNoteData] = useState({});
  const [formData, setFormData] = useState(new DocumentRequestFormData());
  const [serviceProviderServices, setServiceProviderServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newInquiryId, setNewInquiryId] = useState(-5);

  useEffect(() => {
    ServiceProviderHelper.getServiceProviderServices(user.providerId).then(response => {
      setServiceProviderServices(response.data);
    });
  }, []);

  const createNewInquiry = async () => {
    const response = await createInquiry(user, selSignupClientId, formData.title, formData.description);

    if (response.success) {
      // console.log(response.inquiryid);
      await setNewInquiryId(response.inquiryid);
      hidePopup(response.inquiryid);
    } else {
      // console.log('Inquiry failed', response);
      await setNewInquiryId(-1);
      hidePopup(-1);
    }
  };

  const createClientInquiry = async () => {
    try {
      const response = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/createclientinquiry',
        'POST',
        JSON.stringify({
          clientid: selSignupClientId,
          userid: user.id,
          subject: formData.title,
          inquirytext: formData.description,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      if (response[0].create_client_inquiry) {
        await setNewInquiryId(response[0].create_client_inquiry);
        hidePopup(response[0].create_client_inquiry);
      } else {
        await setNewInquiryId(-1);
        hidePopup(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Popup
        title={'Add New Inquiry'}
        width={600}
        height={600}
        visible={showPopup}
        resizeEnabled={true}
        onShowing={() => {
          setFormData(new DocumentRequestFormData());
          setNewInquiryId(-5);
        }}
        onHiding={() => {
          if (newInquiryId === -5) {
            hidePopup(-1);
          }
        }}
      >
        <div id='add-query-pop-form' className='form-container'>
          <Form formData={formData} showColonAfterLabel={true} id={'form'}>
            <Item
              dataField='title'
              label={{ text: 'Title', alignment: 'left', location: 'top' }}
              editorOptions={{
                displayExpr: 'label',
                stylingMode: 'underlined',
              }}
            />
            <Item
              dataField='description'
              editorType='dxTextArea'
              editorOptions={{
                height: '340px',
              }}
              label={{
                alignment: 'left',
                location: 'top',
              }}
            />
            <Item>
              <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type={'default'}
                    useSubmitBehavior={false}
                    onClick={createClientInquiry}
                    text='Send'
                    stylingMode='contained'
                    width={120}
                    height={40}
                  >
                    <span className='dx-button-text'>
                      {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Save'}
                    </span>
                  </Button>
                  <div style={{ marginLeft: '10px' }}>
                    <Button
                      width={120}
                      height={40}
                      text='close'
                      type='normal'
                      stylingMode='contained'
                      onClick={() => {
                        hidePopup(-1);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Item>
          </Form>
        </div>
      </Popup>
    </>
  );
}

export default AddInquiry;
