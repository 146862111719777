import React, { useState, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import AlertPanel from '../user-panel/alert-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { useHttpClient } from '../../hooks/http-hook';
import { useAuth } from '../../contexts/auth';

const Header = ({ menuToggleEnabled, title, toggleMenu }) => {
  const { user } = useAuth();
  const [alertMessages, setAlertMessages] = useState([]);
  const [cntMessages, setCntMessages] = useState(0);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchNewAlerts = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/alerting/portalalerts/${user.portalid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setAlertMessages(dataArray);
        setCntMessages(dataArray.length);
        // console.log(1234, dataArray);
        // console.log(2345, dataArray.length);
      } catch (err) {}
    };

    if (user) {
      fetchNewAlerts();
    }
  }, [sendRequest]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/alerting/portalalerts/${user.portalid}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setAlertMessages(dataArray);
        setCntMessages(dataArray.length);
        // console.log(1234, dataArray);
        // console.log(2345, dataArray.length);
      } catch (err) {}
    }, 30000);
    return () => clearInterval(interval);
  }, [user]);

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />

        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button className={'alert-button authorization'} width={150} height={'100%'} stylingMode={'text'}>
            <AlertPanel menuMode={'context'} alertMessages={alertMessages} cntMessages={cntMessages} user={user} />
          </Button>
        </Item>

        <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
          <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  );
};

export default Header;
