import React, { useEffect, useRef, useState } from 'react';

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label } from 'devextreme-react/form';

const AppntPop = props => {
  const {
    showPopup,
    hidePopup,
    currentuser,
    caluser,
    calevents,
    selevent,
    passNewAppointment,
  } = props;
  const popupRef = useRef(null);
  const [newAppointment, setNewAppointment] = useState({});

  const appmntData = newAppointment;

  useEffect(() => {
    const setNewAppointmentData = apptevent => {
      const aptData = {};
      switch (apptevent) {
        case 1:
          aptData.text = currentuser.fullname + '- Out of Office';
          aptData.startDate = new Date();
          aptData.endDate = new Date();
          aptData.allDay = false;
          aptData.userid = currentuser.userId;
          aptData.eventid = 1;
          aptData.description = '';
          break;
        case 2:
          aptData.text = currentuser.fullname + ' - Business Trip';
          aptData.startDate = new Date();
          aptData.endDate = new Date();
          aptData.allDay = false;
          aptData.userid = currentuser.userId;
          aptData.eventid = 2;
          aptData.description = '';
          break;
        case 3:
          aptData.text = currentuser.fullname + ' - Leave';
          aptData.startDate = new Date();
          aptData.endDate = new Date();
          aptData.allDay = false;
          aptData.userid = currentuser.userId;
          aptData.eventid = 3;
          aptData.description = '';
          break;
        default:
      }
      setNewAppointment(aptData);
    };

    if (showPopup) {
      setNewAppointmentData(selevent);
    }
  }, [selevent, showPopup, currentuser.userId, currentuser.fullname]);

  return (
    <Popup
      title='Calendar Entry'
      width={600}
      height={600}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={10} />
        <Row ratio={0.1} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              formData={appmntData}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              // onContentReady={e => {
              //   console.log(caluser);
              //   console.log(currentuser);
              // }}
            >
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='text'>
                  <Label text='Event' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={2}>
                <SimpleItem
                  dataField='startDate'
                  editorType='dxDateBox'
                  editorOptions={{ type: 'datetime' }}
                />
                <SimpleItem
                  dataField='endDate'
                  editorType='dxDateBox'
                  editorOptions={{ type: 'datetime' }}
                />
              </Item>
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='allDay' editorType='dxSwitch' />
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={2}>
                <SimpleItem
                  dataField='userid'
                  editorType='dxSelectBox'
                  name='user_listbox'
                  editorOptions={{
                    dataSource: caluser,
                    valueExpr: 'userid',
                    displayExpr: 'text',
                    searchEnabled: true,
                  }}
                >
                  <Label text='User' />
                </SimpleItem>
                <SimpleItem
                  dataField='eventid'
                  editorType='dxSelectBox'
                  name='evnt_listbox'
                  editorOptions={{
                    dataSource: calevents,
                    valueExpr: 'eventid',
                    displayExpr: 'text',
                    searchEnabled: true,
                  }}
                >
                  <Label text='Event Type' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='description'
                  editorType='dxTextArea'
                  editorOptions={{ height: 100 }}
                >
                  <Label text='Comments' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={40}
                text='Save'
                type='normal'
                stylingMode='contained'
                // validationGroup='contactDataVal'
                // useSubmitBehavior={true}
                onClick={() => {
                  passNewAppointment(appmntData);
                  hidePopup();
                }}
              />
              <Button
                width={120}
                height={40}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default AppntPop;
