export const navigation = [
  {
    OPNS: true,
    text: 'Cases',
    path: '/operations',
    icon: 'user',
  },
  {
    CLINT: true,
    text: 'Contacts',
    path: '/contacts',
    icon: 'group',
  },
  {
    OPNS: true,
    text: 'Calendar',
    path: '/companycalendar',
    icon: 'event',
  },
  { MSDOC: true, text: 'Documents', icon: 'doc', path: '/documents' },
  {
    STTNG: true,
    text: 'Settings',
    icon: 'preferences',
    isExpanded: false,
    items: [
      {
        STTNG: true,
        text: 'Portals',
        icon: 'spinnext',
        path: '/portals',
      },
      {
        STTNG: true,
        text: 'Portal Users',
        icon: 'spinnext',
        path: '/portalusers',
      },
      {
        STTNG: true,
        text: 'Lookup Fields',
        icon: 'spinnext',
        path: '/lookuppage',
      },
      {
        STTNG: true,
        text: 'Mail Templates',
        icon: 'spinnext',
        path: '/mailtemplates',
      },
      {
        STTNG: true,
        text: 'Ops Templates',
        icon: 'spinnext',
        path: '/optemplates',
      },
    ],
  },
];
