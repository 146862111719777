import React, { useEffect, useState, useCallback } from 'react';
import { useHttpClient } from '../../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import ArrayStore from 'devextreme/data/array_store';

import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Toast } from 'devextreme-react/toast';

import MessagePop from '../../../ui_elements/MessagePop';
import AddCustomFile from '../inquiry-dialogs/AddCustomFile';
import InquiryAddDocsPop from '../inquiry-dialogs/InquiryAddDocsPop';
import AcceptRejectDocPop from '../inquiry-dialogs/AcceptRejectDocPop';

const InquiryDocuments = props => {
  const { user, selInquiryId, selPartnerid } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showInquiryAddDocsPop, setShowInquiryAddDocsPop] = useState(false);
  const [reloadDocData, setReloadDocData] = useState(false);
  const [toastConfigVisible, setToastConfigVisible] = useState(false);
  const [showAddCustomFilePopup, setShowAddCustomFilePopup] = useState(false);
  const [threadAttachedDocument, setThreadAttachedDocument] = useState();
  const [requiredDocData, setRequiredDocData] = useState();
  const [selectedDockey, setSelectedDockey] = useState();
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [clientUploadedDocuments, setClientUploadedDocuments] = useState();
  const [downloadFileRow, setDownloadFileRow] = useState();
  const [selectedDownloadkey, setSelectedDownloadkey] = useState();
  const [documentStatus, setDocumentStatus] = useState([]);
  const [reloadUploadedData, setReloadUploadedData] = useState(false);
  const [showAcceptReject, setShowAcceptReject] = useState(false);
  const [acceptRejectDocument, setAcceptRejectDocument] = useState(false);
  const [documentsArray, setDocumentsArray] = useState([]);

  useEffect(() => {
    const fetchDocumentsThread = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/inquiries/getDocumentsThread/${selInquiryId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setThreadAttachedDocument(dataArray);
      } catch (err) {
        console.log(err);
      }
    };
    if (selInquiryId) {
      fetchDocumentsThread();
    }
  }, [sendRequest, reloadDocData, selInquiryId]);

  useEffect(() => {
    const fetchClientUploadedDocuments = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/inquiries/getUploadedDocuments/${selInquiryId}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setClientUploadedDocuments(dataArray);
      } catch (err) {}
    };

    const fetchDocumentStatus = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/document/getDocumentStatus/`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setDocumentStatus(dataArray);
      } catch (err) {}
    };

    if (selInquiryId || reloadUploadedData) {
      fetchClientUploadedDocuments();
      fetchDocumentStatus();
    }
  }, [sendRequest, selInquiryId, reloadUploadedData]);

  const threadAttachedDocumentData = new ArrayStore({
    key: 'documentid',
    data: threadAttachedDocument,
  });

  const stReqDocUpload = new ArrayStore({
    key: 'id',
    data: clientUploadedDocuments,
  });

  const saveDocumentThread = async anSelectedDocument => {
    if (typeof anSelectedDocument !== 'undefined') {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/inquiries/saveDocumentThread',
          'POST',
          JSON.stringify({
            inquiryId: selInquiryId,
            selectedAddFile: anSelectedDocument,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          }
        );
        setReloadDocData(!reloadDocData);
      } catch (err) {
        console.log(err);
      }
    } else {
      // Popup to choose file before being able to add
      setToastConfigVisible(true);
    }
  };

  const downloadFile = async () => {
    try {
      await fetch(process.env.REACT_APP_BACKEND_URL + `/document/getDocumentDownload/${downloadFileRow.id}`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(response => response.blob())
        .then(function (b) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(b);
          fileReader.onloadend = async () => {
            if (fileReader.result) {
              const link = document.createElement('a');
              link.href = fileReader.result.toString();
              link.setAttribute('download', downloadFileRow.file_name);
              document.body.appendChild(link);
              link.click();
            }
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchReqDocs = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/document/getAllSignupDocuments',
          'POST',
          JSON.stringify({
            selPartnerId: selPartnerid,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          }
        );
        setDocumentsArray(dataArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchReqDocs();
  }, [sendRequest, reloadDocData, selInquiryId]);

  const saveDocumentStatus = async anAccept => {
    let anState = 4;
    if (anAccept) {
      anState = 3;
    }
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/documentstatus',
        'POST',
        JSON.stringify({
          documentId: selectedDownloadkey,
          documentStatus: anState,
          inquiryid: selInquiryId,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setReloadUploadedData(!reloadUploadedData);
    } catch (err) {
      console.log(err);
    }
  };

  const removeDocumentThread = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/inquiries/removeDocumentThread',
        'POST',
        JSON.stringify({
          inquiryId: selInquiryId,
          selectedRemoveFile: requiredDocData.documentid,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );
      setReloadDocData(!reloadDocData);
    } catch (err) {
      console.log(err);
    }
  };

  const showInquiryAddDocumentsPopup = () => {
    setShowInquiryAddDocsPop(true);
  };

  const hideInquiryAddDocsPop = async (blsavedoc, anSelDocument) => {
    if (blsavedoc) {
      await saveDocumentThread(anSelDocument);
    }
    setShowInquiryAddDocsPop(false);
  };

  const hideAddCustomFilePopup = () => {
    setShowAddCustomFilePopup(false);
    setReloadDocData(!reloadDocData);
  };

  const AddCustomFilePopup = () => {
    setShowAddCustomFilePopup(true);
  };

  const removeReqDoc = () => {
    if (selectedDockey) {
      setReloadDocData(reloadDocData => !reloadDocData);
      setShowMessagePop(true);
    }
  };

  const cellRenderDocumentStatus = data => {
    for (let i = 0; i < documentStatus.length; i++) {
      if (documentStatus[i].id === data.value) {
        return documentStatus[i].label;
      }
    }
  };

  const hideAcceptReject = anValue => {
    //  console.log(1111, anValue);
    // console.log(2222, downloadFileRow);
    setAcceptRejectDocument(anValue);
    saveDocumentStatus(anValue);
    setShowAcceptReject(false);
  };

  const hideMessagePop = useCallback(doRemove => {
    setShowMessagePop(false);
    if (doRemove) {
      removeDocumentThread().then(r => setReloadDocData(reloadDocData => !reloadDocData));
      setSelectedDockey();
      setRequiredDocData();
    }
  });

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Remove the selected Document?'
      />
      <AddCustomFile
        showAddCustomFilePopup={showAddCustomFilePopup}
        hideAddCustomFilePopup={hideAddCustomFilePopup}
        selInquiryId={selInquiryId}
        selPartnerId={selPartnerid}
      />
      <InquiryAddDocsPop
        showPopup={showInquiryAddDocsPop}
        hidePopup={hideInquiryAddDocsPop}
        user={user}
        documentsArray={documentsArray}
      />
      <AcceptRejectDocPop showPopup={showAcceptReject} hidePopup={hideAcceptReject} />
      <div id='inquiry-doc-req-section'>
        <p>
          <b>Document Requests</b>
        </p>
        <ResponsiveBox>
          <Row ratio={1} />
          <Row ratio={0.1} />
          <Row ratio={1} />
          <Col ratio={1} />
          <Col ratio={4} />
          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div id='hm-btn-section-1'>
              <p>Add from</p>
            </div>
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Template'
                type='default'
                stylingMode='outlined'
                onClick={() => {
                  showInquiryAddDocumentsPopup();
                }}
              />
            </div>
            <div id='hm-btn-section-11'>
              <Button
                width={120}
                height={36}
                text='From File'
                type='success'
                stylingMode='outlined'
                onClick={() => {
                  AddCustomFilePopup();
                }}
              />
            </div>
            <div id='hm-btn-section-1'>
              <p>Document</p>
            </div>
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Remove'
                type='danger'
                stylingMode='outlined'
                disabled={!requiredDocData || !selectedDockey ? true : false}
                onClick={removeReqDoc}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <div>
              <DataGrid
                id='inquiry-req-doc-grid'
                dataSource={threadAttachedDocumentData}
                width='480px'
                height='260px'
                defaultFocusedRowIndex={0}
                showBorders={true}
                showRowLines={true}
                showColumnLines={false}
                focusedRowEnabled={true}
                hoverStateEnabled={true}
                showColumnHeaders={false}
                onFocusedRowChanged={e => {
                  setRequiredDocData(e.row.data);
                  setSelectedDockey(e.row.key);
                }}
              >
                <Column dataField='id' visible={false} />
                <Column dataField='usage_advice' caption='Document Title' />
                <Column dataField='file_name' caption='Required Document' />
              </DataGrid>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={1} col={0} />
            <div>
              <p>
                <b>Client Upload</b>
              </p>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={2} col={0} />
            <div id='hm-btn-section-1'>
              <p>Document</p>
            </div>
            <div id='hm-btn-section-1'>
              <Button
                width={120}
                height={36}
                text='Download'
                type='default'
                stylingMode='outlined'
                disabled={!downloadFileRow ? true : false}
                onClick={() => {
                  if (selectedDownloadkey) {
                    downloadFile();
                  }
                }}
              />
            </div>

            <div id='hm-btn-section-11'>
              <Button
                width={120}
                height={36}
                text='Accept'
                type='success'
                stylingMode='outlined'
                disabled={!downloadFileRow ? true : false}
                onClick={() => {
                  setShowAcceptReject(true);
                }}
              />
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={2} col={1} />
            <DataGrid
              id='req-doc-up-Grid'
              dataSource={stReqDocUpload}
              width='480px'
              height='260px'
              defaultFocusedRowIndex={0}
              showBorders={true}
              showRowLines={true}
              showColumnLines={false}
              focusedRowEnabled={true}
              hoverStateEnabled={true}
              showColumnHeaders={false}
              onFocusedRowChanged={e => {
                setDownloadFileRow(e.row.data);
                setSelectedDownloadkey(e.row.key);
              }}
            >
              <Column dataField='id' visible={false} />
              <Column dataField='file_name' caption='File Name' />
              <Column
                alignment='center'
                caption='Document Status'
                dataField='status_id'
                width={120}
                cellRender={cellRenderDocumentStatus}
              />
            </DataGrid>
          </Item>
        </ResponsiveBox>
      </div>
      <Toast
        visible={toastConfigVisible}
        message='Choose a document template first.'
        type='error'
        displayTime={4000}
        onHiding={() => {
          setToastConfigVisible(false);
        }}
      />
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default InquiryDocuments;
