import React, { useState, useEffect, useCallback } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import CasesNotesToPop from '../operations/CasesNotesToPop';

const ContactDetails = props => {
  const { tabVisibleIndex, contactid, user, reloadNewsGridCondition } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [contactData, setContactData] = useState({});

  const [showCasesNotesTo, setShowCasesNotesTo] = useState(false);
  const [noteEntryId, setNoteEntryId] = useState(-1);
  const [noteCaseEntryid, setNoteCaseEntryid] = useState(-1);
  const [noteCaseSituation, setNoteCaseSituation] = useState(-1);
  const [contactPortalid, setContactPortalid] = useState(-1);
  const [contactClient, setContactClient] = useState(false);
  const [contactClientid, setContactClientid] = useState(-1);
  const [contactClientMail, setContactClientMail] = useState('');

  const [caseSituationAction, setCaseSituationAction] = useState(false);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/singlecontact/${contactid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setContactData(dataArray[0]);
      } catch (err) {}
    };
    // console.log(121212, contactid);
    if (contactid) {
      fetchContactData();
    }
  }, [sendRequest, contactid]);

  const contactSelectedClient = async () => {
    setContactClientid(contactData.signupid);
    setCaseSituationAction(false);
    setNoteCaseSituation(16);
    setContactPortalid(contactData.portalid);
    setContactClient(true);
    setShowCasesNotesTo(true);
  };

  const contactClientMerchant = () => {
    setContactClient(false);
    setContactClientid(contactData.clientid);
    setContactPortalid(contactData.portalid);
    setNoteCaseSituation(17);
    setShowCasesNotesTo(true);
  };

  const contactClientProvider = async () => {
    setContactClient(false);
    setContactClientid(contactData.signupid);
    setContactPortalid(-10);
    setNoteCaseSituation(18);
    setShowCasesNotesTo(true);
  };

  const hideCasesNotesTo = async () => {
    await setShowCasesNotesTo(false);
    await setNoteEntryId(-1);
    await setNoteCaseEntryid(-1);
    await setContactPortalid(-1);
    await setContactClient(false);
    await setContactClientid(-1);
    await setContactPortalid(-1);
    await setNoteCaseSituation(-1);
    await reloadNewsGridCondition(true);
  };

  return (
    <React.Fragment>
      <CasesNotesToPop
        showPopup={showCasesNotesTo}
        hidePopup={hideCasesNotesTo}
        user={user}
        entryid={noteEntryId}
        caseEntryid={noteCaseEntryid}
        caseSituation={noteCaseSituation}
        contactPortalid={contactPortalid}
        contactClient={contactClient}
        contactClientid={contactClientid}
        contactClientMail={contactClientMail}
        clientData={contactData}
        caseSituationAction={caseSituationAction}
      />

      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1.5} />
        <Col ratio={9} />

        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <p>Reach out to</p>
          </div>
          <div id='hm-btn-section-1'>
            <Button width={120} height={36} text='Contact' type='default' disabled={true} onClick={contactSelectedClient} />
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='client-access-info'>
            <table>
              <tbody>
                <tr>
                  <td width='100px'>Name</td>
                  <td width='250px'>
                    {contactData && contactData.lastname} {contactData && contactData.firstname}{' '}
                    {contactData && contactData.salutation}{' '}
                  </td>
                  <td width='70px'></td>
                  <td width='100px'></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td>{contactData && contactData.company}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>E-Mail</td>
                  <td>{contactData && contactData.email}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{contactData && contactData.phone_one}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Position</td>
                  <td>{contactData && contactData.jobtitle}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default ContactDetails;
