import React, { useRef, useState, useEffect } from 'react';
import './OpTemplates.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Form, SimpleItem, Label, EmptyItem } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import Tabs from 'devextreme-react/tabs';
import { MultiView } from 'devextreme-react';

const OpTemplatesTab = props => {
  const { user } = props;
  const selPortalid = 1;

  const [reloadData, setReloadData] = useState(false);
  const [opsTemplatesData, setOpsTemplatesData] = useState({});
  const [caseSituationsData, setCaseSituationsData] = useState([]);
  const [selSituation, setSelSituation] = useState(-1);
  const [sendtoReceiverData, setSendtoReceiverData] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [tabIndex, setTabIndex] = useState(0);
  const [opsSitData, setOpsSitData] = useState([]);
  const [opsSitGridData, setOpsSitGridData] = useState({});
  const [reloadOps, setReloadOps] = useState(false);
  const [mailTemplatesData, setMailTemplatesData] = useState([]);

  const oData = {
    case_situation: opsTemplatesData && opsTemplatesData.case_situation,
    portalid: opsTemplatesData && opsTemplatesData.portalid,
    sys_subject: opsTemplatesData && opsTemplatesData.sys_subject,
    sys_content: opsTemplatesData && opsTemplatesData.sys_content,
    sys_to: opsTemplatesData && opsTemplatesData.sys_to,
    adm_subject: opsTemplatesData && opsTemplatesData.adm_subject,
    adm_content: opsTemplatesData && opsTemplatesData.adm_content,
    adm_to: opsTemplatesData && opsTemplatesData.adm_to,
    prov_subject: opsTemplatesData && opsTemplatesData.prov_subject,
    prov_content: opsTemplatesData && opsTemplatesData.prov_content,
    prov_to: opsTemplatesData && opsTemplatesData.prov_to,
    adm_mail_templateid: opsTemplatesData && opsTemplatesData.adm_mail_templateid,
    adm_send_mail: opsTemplatesData && opsTemplatesData.adm_send_mail,
    adm_sms_templateid: opsTemplatesData && opsTemplatesData.adm_sms_templateid,
    adm_send_sms: opsTemplatesData && opsTemplatesData.adm_send_sms,
  };

  const sitState = {
    case_situation: opsSitGridData && opsSitGridData.case_situation,
    case_situation_state: opsSitGridData && opsSitGridData.case_situation_state,
    admin_action: opsSitGridData && opsSitGridData.admin_action,
    admin_action_text: opsSitGridData && opsSitGridData.admin_action_text,
    merchant_action: opsSitGridData && opsSitGridData.merchant_action,
    merchant_action_text: opsSitGridData && opsSitGridData.merchant_action_text,
    provider_action: opsSitGridData && opsSitGridData.provider_action,
    provider_action_text: opsSitGridData && opsSitGridData.provider_action_text,
  };

  useEffect(() => {
    const fetchOpsTemplates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/opstemplate/${selSituation}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );

        await setOpsTemplatesData(dataArray[0]);
      } catch (err) {}
    };

    if (selSituation || reloadData) {
      fetchOpsTemplates();
    }
  }, [sendRequest, selSituation, reloadData]);

  useEffect(() => {
    const fetchOpsSitState = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/opssituationstates/${selSituation}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );

        await setOpsSitData(dataArray);
      } catch (err) {}
    };

    if (selSituation || reloadOps) {
      fetchOpsSitState();
    }
  }, [sendRequest, selSituation, reloadOps]);

  useEffect(() => {
    const fetchCaseSituations = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/41',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setCaseSituationsData(dataArray);
      } catch (err) {}
    };

    const fetchSendtoReceiver = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/42',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setSendtoReceiverData(dataArray);
      } catch (err) {}
    };

    fetchCaseSituations();
    fetchSendtoReceiver();
  }, [sendRequest]);

  useEffect(() => {
    const fetchMailTemplates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/email/mailtemplates/${selPortalid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setMailTemplatesData(dataArray);
      } catch (err) {}
    };
    if (selPortalid) {
      fetchMailTemplates();
    }
  }, [sendRequest, selPortalid, reloadData]);

  const stMailTemplatesData = new ArrayStore({
    key: 'template_id',
    data: mailTemplatesData,
  });

  const stCaseSituationData = new ArrayStore({
    key: 'lp_id',
    data: caseSituationsData,
  });

  const stsendtoReceiverData = new ArrayStore({
    key: 'lp_id',
    data: sendtoReceiverData,
  });

  const stOpsSitData = new ArrayStore({
    key: 'case_situation_state',
    data: opsSitData,
  });

  const modifyOpsTemplate = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/modopstemplate',
        'POST',
        JSON.stringify({
          operation: modify,
          case_situation: selSituation,
          portalid: 1,
          sys_subject: oData.sys_subject,
          sys_content: oData.sys_content,
          sys_to: oData.sys_to,
          adm_subject: oData.adm_subject,
          adm_content: oData.adm_content,
          adm_to: oData.adm_to,
          prov_subject: oData.prov_subject,
          prov_content: oData.prov_content,
          prov_to: oData.prov_to,
          adm_mail_templateid: oData.adm_mail_templateid,
          adm_send_mail: oData.adm_send_mail,
          adm_sms_templateid: oData.adm_sms_templateid,
          adm_send_sms: oData.adm_send_sms,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadData(!reloadData);
    } catch (err) {
      console.log(err);
    }
  };

  const modifyOpsSituation = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/modopssituationstate',
        'POST',
        JSON.stringify({
          operation: modify,
          case_situation: selSituation,
          case_situation_state: sitState.case_situation_state,
          admin_action: sitState.admin_action,
          admin_action_text: sitState.admin_action_text,
          merchant_action: sitState.merchant_action,
          merchant_action_text: sitState.merchant_action_text,
          provider_action: sitState.provider_action,
          provider_action_text: sitState.provider_action_text,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadOps(!reloadOps);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <div id='op-template-form'>
        <ResponsiveBox>
          <Row ratio={0} />
          <Col ratio={5} />
          <Col ratio={12} />

          <Item>
            <Location screen='md lg sm xs' row={0} col={0} />
            <div>
              <DataGrid
                id='ops-template-grid'
                dataSource={stCaseSituationData}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={true}
                onFocusedRowChanged={e => {
                  e.row && setSelSituation(e.row.key);
                }}
              >
                <Column dataField='lp_id' visible={true} caption='ID' width='50' />
                <Column dataField='lp_value' caption='Situation' width='250'></Column>
              </DataGrid>
            </div>
          </Item>
          <Item>
            <Location screen='md lg sm xs' row={0} col={1} />
            <ResponsiveBox>
              <Row ratio={9} />
              <Row ratio={1} />
              <Col />
              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <div>
                  <Form
                    id={'mail-template-form'}
                    formData={oData}
                    labelLocation={'left'}
                    colCountByScreen={colCountByScreen}
                  >
                    <Item itemType='group' colCount={10}>
                      <SimpleItem dataField='adm_subject' colSpan={6}>
                        <Label text='Subject' />
                      </SimpleItem>
                      <SimpleItem
                        dataField='adm_to'
                        editorType='dxSelectBox'
                        colSpan={4}
                        name='sys_to_listbox'
                        editorOptions={{
                          dataSource: stsendtoReceiverData,
                          valueExpr: 'lp_id',
                          displayExpr: 'lp_value',
                          searchEnabled: true,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text='Send to' />
                      </SimpleItem>
                    </Item>
                    <Item itemType='group' colCount={1}>
                      <SimpleItem dataField='adm_content' editorType='dxTextArea' editorOptions={{ height: 150 }}>
                        <Label text='Message' />
                      </SimpleItem>
                    </Item>
                    <Item itemType='group' colCount={10}>
                      <SimpleItem dataField='adm_send_mail' colSpan={2} editorType='dxCheckBox'>
                        <Label text='Send Mail' />
                      </SimpleItem>
                      <SimpleItem
                        dataField='adm_mail_templateid'
                        editorType='dxSelectBox'
                        colSpan={4}
                        name='adm_mail_listbox'
                        editorOptions={{
                          dataSource: stMailTemplatesData,
                          valueExpr: 'template_id',
                          displayExpr: 'template_name',
                          searchEnabled: true,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text='Mail Template' />
                      </SimpleItem>
                    </Item>
                    <Item itemType='group' colCount={10}>
                      <SimpleItem dataField='adm_send_sms' colSpan={2} editorType='dxCheckBox'>
                        <Label text='Send SMS' />
                      </SimpleItem>
                      <SimpleItem
                        dataField='adm_sms_templateid'
                        editorType='dxSelectBox'
                        colSpan={4}
                        name='adm_sms_listbox'
                        editorOptions={{
                          // dataSource: stMailTemplatesData,
                          valueExpr: 'template_id',
                          displayExpr: 'template_name',
                          searchEnabled: true,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text='SMS Template' />
                      </SimpleItem>
                    </Item>
                    <EmptyItem />
                    <Item itemType='group' colCount={10}>
                      <SimpleItem dataField='prov_subject' colSpan={6}>
                        <Label text='Response Subject' />
                      </SimpleItem>
                      <SimpleItem
                        dataField='prov_to'
                        editorType='dxSelectBox'
                        colSpan={4}
                        name='sys_to_listbox'
                        editorOptions={{
                          dataSource: stsendtoReceiverData,
                          valueExpr: 'lp_id',
                          displayExpr: 'lp_value',
                          searchEnabled: true,
                          stylingMode: 'underlined',
                        }}
                      >
                        <Label text='Response Send to' />
                      </SimpleItem>
                    </Item>
                    <Item itemType='group' colCount={1}>
                      <SimpleItem dataField='prov_content' editorType='dxTextArea' editorOptions={{ height: 150 }}>
                        <Label text='Response Message' />
                      </SimpleItem>
                    </Item>
                  </Form>
                </div>
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={1} col={0} />
                <div id={'closebutton-2'}>
                  <div style={{ flexDirection: 'row' }}>
                    <Button
                      id='templatebutton-right'
                      width={120}
                      height={36}
                      text='Save'
                      type='success'
                      stylingMode='contained'
                      // useSubmitBehavior={true}
                      onClick={() => {
                        modifyOpsTemplate(2);
                      }}
                    />
                    <Button
                      width={120}
                      height={36}
                      text='Cancel'
                      type='normal'
                      stylingMode='contained'
                      onClick={() => {
                        setReloadData(!reloadData);
                      }}
                    />
                  </div>
                </div>
              </Item>
            </ResponsiveBox>
          </Item>
        </ResponsiveBox>
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default OpTemplatesTab;
