import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import { v4 as uuid } from 'uuid';
import './CalendarTab.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Scheduler, { Resource } from 'devextreme-react/scheduler';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import AppntPop from './AppntPop';

const calendarviews = ['day', 'week', 'workWeek', 'month'];
// const currentDate = new Date().today();

const getscreenheight = () => {
  const anValue = window.innerHeight - 200;
  return anValue;
};

const CalendarTab = props => {
  const user = props.user;
  const selCompanyID = props.selCompanyID;
  const calendarRef = useRef(null);
  const popupRef = useRef(null);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [calendarEntries, setCalendarEntries] = useState([]);
  const [apptmnentAdded, setApptmentAdded] = useState(false);
  const [calendarUser, setCalendarUser] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [eventType, setEventType] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchCalendarUser = async () => {
      try {
         const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/calendar/crmcaluser/${user.portalid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setCalendarUser(dataArray);
      } catch (err) {}

    };

    const fetchCalendarEvents = async () => {
      try {
         const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            '/calendar/crmcalevent',
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setCalendarEvents(dataArray);
      } catch (err) {}
    };

    fetchCalendarUser();
    fetchCalendarEvents();
  }, [sendRequest]);

  useEffect(() => {
    const fetchCalendarEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/calendar/crmcalentries/${user.portalid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
         setCalendarEntries(dataArray);
      } catch (err) {}
    };
    fetchCalendarEntries();
  }, [sendRequest, apptmnentAdded, selCompanyID, user.portalid]);

  const caldata = calendarEntries;

  const stcaldata = new ArrayStore({
    key: 'entryid',
    data: calendarEntries,
  });

  const dcCalData = new DataSource({
    store: stcaldata,
  });

  const stuserdata = new ArrayStore({
    key: 'userid',
    data: calendarUser,
  });

  const dcUserData = new DataSource({
    store: stuserdata,
  });

  const steventdata = new ArrayStore({
    key: 'eventid',
    data: calendarEvents,
  });

  const dcEventData = new DataSource({
    store: steventdata,
  });

  const saveCalendarentries = async apptment => {
    try {
      await sendRequest( process.env.REACT_APP_BACKEND_URL + '/calendar/modcalentries', 'POST', JSON.stringify(apptment), 
      { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token },
      );
      if ((apptment.operation = 1)) {
        setApptmentAdded(!apptmnentAdded);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const newUserApptment = {
    text: 'New Appointment',
    operation: 1,
    startDate: new Date(),
    endDate: new Date(),
    entryid: uuid,
    userid: user.userId,
    eventid: 1,
    ucompanyid: user.companyid,
  };

  const saveAddedAppointment = e => {
    const apptment = e.appointmentData;
    apptment['operation'] = 1;
    apptment['entryid'] = uuid;
    apptment['portalid'] = user.portalid;
    apptment['userid'] = user.userId;
    saveCalendarentries(apptment);
  };

  const saveUpdatedAppointment = e => {
    const apptment = e.appointmentData;
    apptment['operation'] = 2;
    if (!apptment['entryid']) {
      apptment['entryid'] = uuid;
      apptment['portalid'] = user.portalid;
      apptment['userid'] = user.userId;
    }
    saveCalendarentries(apptment);
  };

  const saveDeletedAppointment = e => {
    const apptment = e.appointmentData;
    apptment['operation'] = 3;
    if (!apptment['entryid']) {
      apptment['entryid'] = uuid;
      apptment['portalid'] = user.portalid;
      apptment['userid'] = user.userId;
    }
    saveCalendarentries(apptment);
  };

  const CaladdAppointment = apptData => {
    const apptment = apptData;
    apptment['operation'] = 1;
    apptment['entryid'] = uuid;
    apptment['portalid'] = user.portalid;
    saveCalendarentries(apptment);
    //  an_calendar.addAppointment(apptData);
  };

  const hidePopup = useCallback(() => {
    setShowPopup(false);
  });

  const showCalendarPopup = aneventtype => {
    setEventType(aneventtype);
    setShowPopup(true);
  };

  return (
    <React.Fragment>
      <AppntPop
        showPopup={showPopup}
        hidePopup={hidePopup}
        currentuser={user}
        caluser={dcUserData}
        calevents={dcEventData}
        selevent={eventType}
        passNewAppointment={CaladdAppointment}
      />
      <ResponsiveBox>
        <Col ratio={0.4} screen='md lg sm' />
        <Col ratio={2} />
        <Row />

        <Item>
          <Location screen='md lg sm' row={0} col={0} />
          <div id='hm-btn-section-1'>
            <div className='dx-fieldset-header' id='cap_item'>
              Set
            </div>
            <Button
              width={170}
              height={50}
              text='Business-Trip'
              type='normal'
              stylingMode='outlined'
              onClick={() => {
                showCalendarPopup(2);
              }}
            />
          </div>
          <div id='hm-btn-section-2'>
            <Button
              width={170}
              height={50}
              text='Out of Office'
              type='normal'
              stylingMode='outlined'
              onClick={() => {
                showCalendarPopup(1);
              }}
            />
            <div id='calbtnsave'>
              <Button
                width={170}
                height={50}
                text='Leave'
                type='normal'
                stylingMode='outlined'
                onClick={() => {
                  showCalendarPopup(3);
                }}
              />
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm' row={0} col={1} />
          <Location screen='xs' row={0} col={0} />
          <div id='schedpos'>
            <Scheduler
              id='companycalendar'
              defaultCurrentView='month'
              timeZone='Europe/Berlin'
              crossScrollingEnabled={true}
              dataSource={dcCalData}
              ref={calendarRef}
              views={calendarviews}
              cellDuration={60}
              startDayHour={8}
              endDayHour={23}
              height={getscreenheight}
              // adaptivityEnabled={true}
              //  defaultCurrentDate={new DataGrid(2021, 1, 24)}
              onAppointmentAdded={saveAddedAppointment}
              onAppointmentUpdated={saveUpdatedAppointment}
              onAppointmentDeleted={saveDeletedAppointment}
            >
              <Resource
                fieldExpr='eventid'
                valueExpr='eventid'
                allowMultiple={false}
                dataSource={dcEventData}
                label='Event'
                useColorAsDefault={true}
              />
              <Resource
                fieldExpr='userid'
                valueExpr='userid'
                allowMultiple={false}
                dataSource={dcUserData}
                label='Owner'
              />
            </Scheduler>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CalendarTab;
