import React, { useEffect, useRef, useState } from 'react';
import './ContactEditPop.css';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { CheckBox } from 'devextreme-react/check-box';

const ContactTypePop = props => {
  const { showPopup, hidePopup } = props;
  const [approveClient, setApproveClient] = useState(true);
  const [additionalDetails, setAdditionalDetails] = useState(false);
  const [rejectClientApproval, setRejectClientApproval] = useState(false);
  const [selContactType, setSelContactType] = useState(3);

  const checkedOptions = selValue => {
    setSelContactType(selValue);

    switch (selValue) {
      case 3:
        setApproveClient(true);
        setAdditionalDetails(false);
        setRejectClientApproval(false);
        break;
      case 2:
        setApproveClient(false);
        setAdditionalDetails(true);
        setRejectClientApproval(false);
        break;
      case 1:
        setApproveClient(false);
        setAdditionalDetails(false);
        setRejectClientApproval(true);
        break;
      default:
        setApproveClient(true);
        setAdditionalDetails(false);
        setRejectClientApproval(false);
    }
  };

  const saveApprovalDecision = async () => {
    hidePopup(selContactType);
  };

  return (
    <Popup
      title={'Add'}
      width={500}
      height={240}
      visible={showPopup}
      onHiding={() => {
        hidePopup(selContactType);
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Row ratio={0.1} />
        <Col ratio={1} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='contact-type-select'>
            <ResponsiveBox id='client-approval-pop-cbx'>
              <Row ratio={1} />
              <Col ratio={1} />
              <Col ratio={1} />
              <Col ratio={1} />
              <Item>
                <Location screen='md lg sm xs' row={0} col={0} />
                <CheckBox
                  text='Company'
                  value={approveClient}
                  onValueChanged={e => {
                    if (e.value === true) {
                      checkedOptions(3);
                    }
                  }}
                />
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={0} col={1} />
                <CheckBox
                  text='Employee'
                  value={additionalDetails}
                  onValueChanged={e => {
                    if (e.value === true) {
                      checkedOptions(2);
                    }
                  }}
                />
              </Item>
              <Item>
                <Location screen='md lg sm xs' row={0} col={2} />
                <CheckBox
                  text='Other Contact'
                  value={rejectClientApproval}
                  onValueChanged={e => {
                    if (e.value === true) {
                      checkedOptions(1);
                    }
                  }}
                />
              </Item>
            </ResponsiveBox>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'access-pop-closebutton-right'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='access-pop-savebutton-right'
                width={120}
                height={46}
                text='Continue'
                type='default'
                stylingMode='contained'
                // validationGroup='serviceFeatureValGrp'
                // useSubmitBehavior={true}
                onClick={e => {
                  saveApprovalDecision();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ContactTypePop;
