import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./operations.scss";

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import TabPanel from "devextreme-react/tab-panel";
import { Button, MultiView, SelectBox } from "devextreme-react";
import Tabs from "devextreme-react/tabs";

import CasesOneTab from "./CasesOneTab";
import CasesNotesTab from "./CasesNotesTab";
import CasesNews from "./CasesNews";
import { useAuth } from "../../contexts/auth";

import ContactDetails from "../contacts/ContactDetails";
import ClientsInquiry from "../../modules/inquiries/Inquiries";

const OperationsPage = () => {
  const { user } = useAuth();
  const selPartnerPortalRef = useRef();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [caseDetails, setCaseDetails] = useState({});
  const [newsKey, setNewsKey] = useState();
  const [reloadCasesGrids, setReloadCasesGrids] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [lpCaseTypes, setLpCaseTypes] = useState([]);
  const [lpCaseStates, setLpCaseStates] = useState([]);
  const [lpCasePrio, setLpCasePrio] = useState([]);
  const [lpCaseSituation, setLpCaseSituation] = useState([]);

  const [selPartnerPortal, setSelPartnerPortal] = useState(user.portalid);
  const [reloadNewsGrid, setReloadNewsGrid] = useState(false);

  const [newCaseDetails, setNewCaseDetails] = useState({});
  const [selEntryid, setSelEntryid] = useState(0);

  const baseformData = {
    // selCompanyid: selectedCompany,
    selLfdNr: -1,
    selBase_LfdNr: -1,
  };

  const getKeyValues = useCallback(
    (an_value, an_subject, an_contact, an_contactid) => {
      setCaseDetails({
        entryid: an_value,
        case_entryid: an_value,
        subject: an_subject,
        contact: an_contact,
        contactid: an_contactid,
      });
    }
  );

  const getNewsEntryIds = useCallback(
    (an_entryid, an_case_entryid, an_contactid) => {
      setSelEntryid(an_entryid);
      setCaseDetails({
        entryid: an_entryid,
        case_entryid: an_case_entryid,
        subject: "",
        contact: "",
        contactid: an_contactid,
      });
    }
  );

  const getReloadCondition = useCallback((doGridReload) => {
    setReloadCasesGrids(doGridReload);
  });

  useEffect(() => {
    const fetchCaseTypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/40",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseTypes(dataArray);
      } catch (err) {}
    };

    const fetchCaseStates = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/39",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseStates(dataArray);
      } catch (err) {}
    };

    const fetchCasePrio = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/38",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCasePrio(dataArray);
      } catch (err) {}
    };

    const fetchCaseSituation = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/base/getlookupvalues/41",
          "GET",
          null,
          { Authorization: "Bearer " + user.token }
        );
        setLpCaseSituation(dataArray);
      } catch (err) {}
    };

    fetchCaseTypes();
    fetchCaseStates();
    fetchCasePrio();
    fetchCaseSituation();
  }, [sendRequest]);

  const stCaseType = new ArrayStore({
    key: "lp_id",
    data: lpCaseTypes,
  });

  const stCaseStates = new ArrayStore({
    key: "lp_id",
    data: lpCaseStates,
  });

  const stCasePrio = new ArrayStore({
    key: "lp_id",
    data: lpCasePrio,
  });

  const stCaseSituation = new ArrayStore({
    key: "lp_id",
    data: lpCaseSituation,
  });

  const reloadNewsGridCondition = async (anValue) => {
    await setReloadNewsGrid(anValue);
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col ratio={3} />
            <Col ratio={1.4} screen="md lg" />

            <Item>
              <Location screen="md lg sm xs" row={1} col={0} />
              <div className="operation-page-content">
                <div className="operation-page-content-container">
                  <ResponsiveBox>
                    <Row />
                    <Col />
                    <Item>
                      <Location screen="md lg sm xs" row={0} col={0} />

                        <CasesOneTab
                          passToParent={getKeyValues}
                          user={user}
                          selPartnerPortal={selPartnerPortal}
                          selnewskey={selEntryid}
                          dataStCaseType={stCaseType}
                          dataStCaseStates={stCaseStates}
                          dataStDevPrios={stCasePrio}
                          dataStCaseSituation={stCaseSituation}
                          getReload={getReloadCondition}
                          reloadGrid={reloadCasesGrids}
                        />

                      <div style={{ flexDirection: "column" }}>
                        <div id="operations-notes-tab">
                          <div>
                            <Tabs
                              selectedIndex={tabIndex}
                              width="100%"
                              visible={true}
                              onItemClick={(e) => {
                                setTabIndex(e.itemIndex);
                              }}
                            >
                              <Item text="Activity" />
                              <Item text="Contact" />
                            </Tabs>
                            <MultiView
                              height={400}
                              animationEnabled={false}
                              selectedIndex={tabIndex}
                            >
                              <Item title="Notes">
                                <CasesNotesTab
                                  tabVisibleIndex={tabIndex}
                                  caseDetails={caseDetails}
                                  selPartnerPortal={selPartnerPortal}
                                  user={user}
                                  getReload={getReloadCondition}
                                  reloadNewsGridCondition={
                                    reloadNewsGridCondition
                                  }
                                />
                              </Item>

                              <Item title="Client Details">
                                <ContactDetails
                                  selectedKey={caseDetails.contactid}
                                  user={user}
                                  contactid={caseDetails.contactid}
                                  reloadNewsGridCondition={
                                    reloadNewsGridCondition
                                  }
                                />
                              </Item>
                            </MultiView>
                          </div>
                        </div>
                      </div>
                    </Item>
                  </ResponsiveBox>
                </div>
              </div>
            </Item>

            <Item>
              <Location screen="md lg sm xs" row={1} col={1} />
              <div className="rb-right-side-bar item">
                <CasesNews
                  user={user}
                  selPartnerPortal={selPartnerPortal}
                  reloadNewsGrid={reloadNewsGrid}
                  reloadNewsGridCondition={reloadNewsGridCondition}
                  getNewsEntryIds={getNewsEntryIds}
                />
              </div>
            </Item>

            <Item>
              <Location screen="md lg" row={2} col={0} colspan={2} />
              <Location screen="sm" row={2} col={0} colspan={1} />
              <Location screen="xs" row={2} col={0} />
              <div className="rb-footer item" id="cap_item"></div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OperationsPage;
