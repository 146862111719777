import React, { useEffect, useRef, useState } from 'react';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem, ButtonOptions } from 'devextreme-react/form';
import { Tabs, MultiView, SelectBox } from 'devextreme-react';
import DataGrid, { Column, Lookup, Editing } from 'devextreme-react/data-grid';

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

import { useHttpClient } from '../../hooks/http-hook';

const UserEditPop = props => {
  const { showPopup, hidePopup, user, selUserId, modeInsert } = props;
  const popupRef = useRef(null);
  const dataFormRef = useRef(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [singleUser, setSingleUser] = useState([]);
  const [lpPortals, setLpPortals] = useState([]);
  const [selProviderid, setSelProviderid] = useState(-1);
  const [tabIndex, setTabIndex] = useState(0);
  const [userRolesData, setUserRolesData] = useState([]);
  const [selRoleid, setSelRoleid] = useState(1);
  const [selRoleData, setSelRoleData] = useState({});
  const [selPermissionid, setSelPermissionid] = useState(-1);
  const [permissionsLp, setPermissionsLp] = useState([]);
  const [rolePermissionsData, setRolePermissionsData] = useState([]);
  const [rolePermissionDataRow, setRolePermissionDataRow] = useState({});
  const [roleMod, setRoleMod] = useState(false);
  const [permissionMod, setPermissionMod] = useState(false);

  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/user/sel-portaluser/${selUserId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setSingleUser(dataArray[0]);
        console.console.log(dataArray[0]);
      } catch (err) {}
    };
    if (modeInsert && showPopup) {
      setSingleUser({});
    } else if (selUserId && showPopup) {
      fetchSingleUser();
    }
  }, [sendRequest, selUserId, showPopup, modeInsert]);

  const userData = {
    operation: modeInsert ? 1 : 2,
    userid: (singleUser && singleUser.id) || -1,
    last_name: (singleUser && singleUser.last_name) || '',
    first_name: (singleUser && singleUser.first_name) || '',
    email: (singleUser && singleUser.email) || '',
    phone: (singleUser && singleUser.phone) || '',
    office: (singleUser && singleUser.office) || '',
    department: (singleUser && singleUser.department) || '',
    portalid: (singleUser && singleUser.portalid) || -1,
    resetpasswordtoken: (singleUser && singleUser.resetPasswordToken) || '',
    user_roleid: (singleUser && singleUser.user_roleid) || 1,
  };

  const roleData = {
    roleid: (selRoleData && selRoleData.id) || -1,
    rolename: (selRoleData && selRoleData.name) || '',
  };

  const permissionData = {
    roleid: (rolePermissionDataRow && rolePermissionDataRow.roleid) || -1,
    permissionid: (rolePermissionDataRow && rolePermissionDataRow.permissionid) || -1,
    permission: (rolePermissionDataRow && rolePermissionDataRow.permission) || '',
  };

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/user/userportals', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setLpPortals(dataArray);
      } catch (err) {}
    };

    const fetchPermissions = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/37',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setPermissionsLp(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchPortals();
      fetchPermissions();
    }
  }, [sendRequest, showPopup]);

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/roles/', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setUserRolesData(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchUserRoles();
    }
  }, [sendRequest, showPopup, roleMod]);

  useEffect(() => {
    const fetchRolePermissions = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/roles/rolepermissions/${selRoleid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setRolePermissionsData(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchRolePermissions();
    }
  }, [sendRequest, showPopup, selRoleid, permissionMod]);

  const stPortals = new ArrayStore({
    key: 'partnerid',
    data: lpPortals,
  });

  const stUserRoles = new ArrayStore({
    key: 'id',
    data: userRolesData,
  });

  const dcUserRoles = new DataSource({
    store: stUserRoles,
  });

  const stPermissionsLp = new ArrayStore({
    key: 'id',
    data: permissionsLp,
  });

  const stRolePermissionsData = new ArrayStore({
    key: 'permissionid',
    data: rolePermissionsData,
  });

  const dcRolePermissionsData = new DataSource({
    store: stRolePermissionsData,
  });

  function navigateToSettings() {
    hidePopup();
    //   history.push({
    //    pathname: '/syssetup',
    //    // closeaction: userSaved,
    //  });
  }

  const saveUser = async () => {
    console.log(userData);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/user/mod-portaluser',
        'POST',
        JSON.stringify({
          operation: userData.operation,
          userid: userData.userid,
          last_name: userData.last_name,
          first_name: userData.first_name,
          email: userData.email,
          phone: userData.phone,
          office: userData.office,
          department: userData.department,
          portalid: userData.portalid,
          resetpasswordtoken: userData.resetpasswordtoken,
          user_roleid: userData.user_roleid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async dataFormRef => {
    const result = dataFormRef.validationGroup.validate();
    if (result.isValid) {
      await saveUser();
      navigateToSettings();
    } else {
      alert('Please complete all required fields');
    }
  };

  const saveRole = async anOp => {
    console.log(roleData);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/roles/modroles',
        'POST',
        JSON.stringify({
          operation: anOp,
          roleid: roleData.roleid,
          rolename: roleData.rolename,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
    setRoleMod(!roleMod);
  };

  const savePermission = async anOp => {
    //  console.log(permissionData);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/roles/modrolepermissions',
        'POST',
        JSON.stringify({
          operation: anOp,
          roleid: selRoleid,
          permissionid: permissionData.permissionid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
    setPermissionMod(!permissionMod);
  };

  return (
    <Popup
      title={'User'}
      width={1100}
      height={800}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup();
      }}
    >
      <ResponsiveBox>
        <Row ratio={1} />
        <Item>
          <Tabs
            selectedIndex={tabIndex}
            width='99%'
            // height='20px'
            visible={true}
            onItemClick={e => {
              setTabIndex(e.itemIndex);
            }}
          >
            <Item text='User' />
            <Item text='Rolses & Permissions' />
          </Tabs>
          <MultiView animationEnabled={false} selectedIndex={tabIndex}>
            <Item title='User'>
              <ResponsiveBox>
                <Row ratio={9} />
                <Row ratio={1} />
                <Item>
                  <Form
                    id={'puser-form'}
                    ref={dataFormRef}
                    formData={userData}
                    labelLocation={'top'}
                    colCountByScreen={colCountByScreen}
                    validationGroup='UserEditData'
                  >
                    <Item itemType='group' colCount={10}>
                      <SimpleItem
                        dataField='portalid'
                        colSpan={4}
                        isRequired={true}
                        editorType='dxSelectBox'
                        name='portal_listbox'
                        editorOptions={{
                          dataSource: stPortals,
                          valueExpr: 'portalid',
                          displayExpr: 'portaltext',
                          searchEnabled: true,
                        }}
                      >
                        <RequiredRule message='Please select a Portal' />
                        <Label text='Portal' />
                      </SimpleItem>
                    </Item>
                    <EmptyItem />

                    <Item itemType='group' colCount={5}>
                      <SimpleItem dataField='last_name' colSpan={2} isRequired={true} editorOptions={baseEditorOptions}>
                        <RequiredRule message='Please enter a Name' />
                        <Label text='Last Name' />
                      </SimpleItem>
                      <SimpleItem dataField='first_name' colSpan={2} isRequired={true}>
                        <RequiredRule message='Please enter a First Name' />
                        <Label text='First Name' />
                      </SimpleItem>
                    </Item>
                    <EmptyItem />

                    <Item itemType='group' colCount={5}>
                      <SimpleItem dataField='email' colSpan={2} isRequired={true}>
                        <RequiredRule message='Please enter a Login' />
                        <Label text='E-Mail' />
                      </SimpleItem>
                      <SimpleItem dataField='phone' colSpan={2} isRequired={true}>
                        <RequiredRule message='Please enter a Login' />
                        <Label text='Smartphone' />
                      </SimpleItem>
                    </Item>
                    <EmptyItem />
                    <Item itemType='group' colCount={5}>
                      <SimpleItem
                        dataField='user_roleid'
                        colSpan={2}
                        isRequired={true}
                        editorType='dxSelectBox'
                        name='roles_listbox'
                        editorOptions={{
                          dataSource: stUserRoles,
                          valueExpr: 'id',
                          displayExpr: 'name',
                        }}
                      >
                        <RequiredRule message='Please select a User Role' />
                        <Label text='User Role' />
                      </SimpleItem>
                      <SimpleItem dataField='resetpasswordtoken' colSpan={2}>
                        <Label text='Password Reset Key' />
                      </SimpleItem>
                    </Item>
                  </Form>
                </Item>
                <Item>
                  <div id={'closebutton'}>
                    <div style={{ flexDirection: 'row' }}>
                      <Button
                        id='savebutton-right'
                        width={120}
                        height={50}
                        text='Save'
                        type='normal'
                        stylingMode='contained'
                        validationGroup='UserEditData'
                        useSubmitBehavior={true}
                        onClick={validateForm}
                      />
                      <Button
                        width={120}
                        height={50}
                        text='Close'
                        type='normal'
                        stylingMode='contained'
                        onClick={navigateToSettings}
                      />
                    </div>
                  </div>
                </Item>
              </ResponsiveBox>
            </Item>
            <Item title='Rolses & Permissions'>
              <div id='user-roles-permissions'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Col ratio={1} />
                  <Col ratio={1} />
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <Form formData={roleData}>
                      <Item itemType='group' colCount={10}>
                        <SimpleItem colSpan={1} dataField='roleid'>
                          <Label text='ID' />
                        </SimpleItem>
                        <SimpleItem colSpan={5} dataField='rolename'>
                          <Label text='Role' />
                        </SimpleItem>

                        <ButtonItem colSpan={1} horizontalAlignment='left'>
                          <ButtonOptions
                            height='100%'
                            type='default'
                            text='+'
                            onClick={() => {
                              saveRole(1);
                            }}
                          ></ButtonOptions>
                        </ButtonItem>
                        <ButtonItem colSpan={1} horizontalAlignment='left'>
                          <ButtonOptions
                            height='100%'
                            type='success'
                            text='s'
                            onClick={() => {
                              saveRole(2);
                            }}
                          ></ButtonOptions>
                        </ButtonItem>
                        <ButtonItem colSpan={1} horizontalAlignment='left'>
                          <ButtonOptions
                            height='100%'
                            type='danger'
                            text='-'
                            onClick={() => {
                              saveRole(3);
                            }}
                          ></ButtonOptions>
                        </ButtonItem>
                      </Item>
                      <Item>
                        <DataGrid
                          id='Portal-User-Role-Grid'
                          dataSource={dcUserRoles}
                          keyExpr={'id'}
                          showBorders={true}
                          showRowLines={true}
                          showColumnLines={true}
                          focusStateEnabled={true}
                          defaultFocusedRowIndex={0}
                          focusedRowKey={selRoleid}
                          focusedRowEnabled={true}
                          onFocusedRowChanged={e => {
                            if (e.row) {
                              setSelRoleid(e.row.key);
                              setSelRoleData(e.row.data);
                              // console.log(e.row.data);
                            }
                          }}
                        >
                          <Column dataField='id' visible={true} caption='ID' width='80' />
                          <Column dataField='name' visible={true} caption='Role' width='320' />
                        </DataGrid>
                      </Item>
                    </Form>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                    <Form formData={permissionData}>
                      <Item itemType='group' colCount={10}>
                        <SimpleItem
                          dataField='permissionid'
                          colSpan={6}
                          editorType='dxSelectBox'
                          name='permission_listbox'
                          editorOptions={{
                            dataSource: stPermissionsLp,
                            valueExpr: 'lp_id',
                            displayExpr: 'lp_value',
                            searchEnabled: true,
                          }}
                        >
                          <Label text='Permissions' />
                        </SimpleItem>

                        <ButtonItem colSpan={1} horizontalAlignment='left'>
                          <ButtonOptions
                            height='100%'
                            type='default'
                            text='+'
                            onClick={() => {
                              savePermission(1);
                            }}
                          ></ButtonOptions>
                        </ButtonItem>
                        <ButtonItem colSpan={1} horizontalAlignment='left'>
                          <ButtonOptions
                            height='100%'
                            type='danger'
                            text='-'
                            onClick={() => {
                              savePermission(3);
                            }}
                          ></ButtonOptions>
                        </ButtonItem>
                      </Item>
                      <Item>
                        <DataGrid
                          id='Portal-User-Role-Grid'
                          dataSource={dcRolePermissionsData}
                          keyExpr={'permissionid'}
                          showBorders={true}
                          showRowLines={true}
                          showColumnLines={true}
                          focusStateEnabled={true}
                          defaultFocusedRowIndex={0}
                          focusedRowKey={selPermissionid}
                          focusedRowEnabled={true}
                          onFocusedRowChanged={e => {
                            if (e.row) {
                              setSelPermissionid(e.row.key);
                              setRolePermissionDataRow(e.row.data);
                              // console.log(e.row.data);
                            }
                          }}
                        >
                          <Column dataField='roleid' visible={false} caption='roleid' width='80' />
                          <Column dataField='permissionid' visible={false} caption='permissionid' width='80' />
                          <Column dataField='permission' visible={true} caption='Permission' width='300' />
                          <Column dataField='pshort' visible={true} caption='P.Short' width='100' />
                        </DataGrid>
                      </Item>
                    </Form>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>
          </MultiView>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = { stylingMode: 'underlined' };
const passwordEditorOptions = { stylingMode: 'underlined', mode: 'password' };

export default UserEditPop;
