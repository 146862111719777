import React, { useCallback, useEffect, useState } from 'react';
import Button from 'devextreme-react/button';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './parnerEditReqDoc.scss';
import { useAuth } from '../../contexts/auth';
import { useHttpClient } from '../../hooks/http-hook';
import ArrayStore from 'devextreme/data/array_store';
import MessagePop from '../../ui_elements/MessagePop';
import PartnerEditReqDocPopup from './partnerEditReqDocPopup';
import PartnerEditReqDocEditPopup from './partnerEditReqDocEditPopup';
import ResponsiveBox, { Col, Item, Location, Row } from 'devextreme-react/responsive-box';
import { CheckBox } from 'devextreme-react/check-box';
import { Toast } from 'devextreme-react/toast';

function PartnerEditReqDoc() {
  const { user } = useAuth();

  const { sendRequest } = useHttpClient();
  const [requiredDocData, setRequiredDocData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [popInsert, setPopInsert] = useState(0);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadDocData, setReloadDocData] = useState(false);
  const [documentsArray, setDocumentsArray] = useState([]);
  const [operationId, setOperationId] = useState(10);
  const [toastConfigVisible, setToastConfigVisible] = useState(false);

  useEffect(() => {
    const fetchReqDocs = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/document/getAllSignupDocuments',
          'POST',
          JSON.stringify({
            operationId: operationId,
            selPartnerId: user.portalid,
            partnerName: user.portalname,
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
          }
        );
        setDocumentsArray(dataArray);
      } catch (err) {}
    };
    fetchReqDocs();
  }, [sendRequest, user.token, reloadDocData, operationId]);

  const removeTouDoc = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/document/removeDocument',
        'POST',
        JSON.stringify({
          id: requiredDocData.id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.token,
        }
      );

      // Reset
      requiredDocData.usage_advice = '';
    } catch (err) {
      console.log(err);
    }
  };

  const stReqDocData = new ArrayStore({
    key: 'id',
    data: documentsArray,
  });

  const addRequiredCustomerDoc = () => {
    setReloadDocData(reloadDocData => !reloadDocData);
    setPopInsert(2);
    setShowPopup(true);
  };

  const hidePopup = () => {
    setShowPopup(false);
    setReloadDocData(reloadDocData => !reloadDocData);
  };

  const hideMessagePop = useCallback(doRemove => {
    if (doRemove) {
      removeTouDoc();
      setReloadDocData(reloadDocData => !reloadDocData);
    }
    setShowMessagePop(false);
    setReloadDocData(reloadDocData => !reloadDocData);
  });

  const removeReqDoc = () => {
    // console.log(requiredDocData)
    if (requiredDocData.usage_advice) {
      setReloadDocData(reloadDocData => !reloadDocData);
      setShowMessagePop(true);
    } else {
      setToastConfigVisible(true);
    }
  };

  const editRequiredCustomerDoc = () => {
    // console.log(requiredDocData)
    if (requiredDocData.usage_advice) {
      setReloadDocData(reloadDocData => !reloadDocData);
      setShowEditPopup(true);
    } else {
      setToastConfigVisible(true);
    }
  };

  const hideEditPopup = () => {
    setShowEditPopup(false);
    setReloadDocData(reloadDocData => !reloadDocData);
  };

  // const handleChange = async (e, data, op) => {
  //   try {
  //     await sendRequest(
  //       process.env.REACT_APP_BACKEND_URL + '/document/updateStateSignup',
  //       'POST',
  //       JSON.stringify({
  //         id: data.key,
  //         state: e.component.option('value'),
  //         operation: op,
  //       }),
  //       {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + user.token,
  //       }
  //     );
  //     setReloadDocData((reloadDocData) => !reloadDocData);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  function cellRenderSignupPerson(data) {
    return (
      <div>
        <CheckBox
          defaultValue={data.value}
          readOnly={true}
          // onValueChanged={(e) => handleChange(e, data, 10)}
        />
      </div>
    );
  }

  function cellRenderSignupCompany(data) {
    return (
      <div>
        <CheckBox
          defaultValue={data.value}
          readOnly={true}
          // onValueChanged={(e) => handleChange(e, data, 20)}
        />
      </div>
    );
  }

  const changeStatusProgress = () => {
    // UPDATE Progress
  };

  return (
    <>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Template?'
      />
      <PartnerEditReqDocPopup
        showPopup={showPopup}
        hidePopup={hidePopup}
        popInsert={popInsert}
        selPartnerId={user.portalid}
        selPersonId={user.id}
        partnername={user.portalname}
        user={user}
        operationId={operationId}
      />
      <PartnerEditReqDocEditPopup
        showEditPopup={showEditPopup}
        hideEditPopup={hideEditPopup}
        user={user}
        requiredDocData={requiredDocData}
      />
      <div className={'content-block'}>
        <div className={'dx-card wide-card'}>
          <ResponsiveBox>
            <Row ratio={0.5} />
            <Row ratio={2} />
            <Row ratio={0.7} />
            <Col />

            <Item>
              <Location screen='md lg' row={0} col={0} colspan={2} />
              <Location screen='sm' row={0} col={0} colspan={1} />
              <Location screen='xs' row={0} col={0} />
              <div className='sd-header item'>
                <ResponsiveBox>
                  <Row />
                  <Col ratio={3} />
                  <Col ration={1} />
                  <Item>
                    <Location screen='md lg sm' row={0} col={0} />
                    <div className='dx-fieldset-header' id='cap_item'>
                      Document Templates
                    </div>
                  </Item>
                  <Item>
                    <Location screen='md lg sm xs' row={0} col={1} />
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>

            <Item>
              <Location screen='md lg sm' row={1} col={0} />
              <Location screen='xs' row={1} col={0} />
              <div className='sd-content item'>
                <ResponsiveBox>
                  <Row ratio={1} />
                  <Row ratio={1} />
                  <Col ratio={1} />

                  <Item>
                    <Location screen='md lg sm xs' row={0} col={0} />
                    <ResponsiveBox>
                      <Col ratio={1.1} screen='md lg sm xs' />
                      <Col ratio={8} />
                      <Row />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                        <div id='hm-btn-section-1'>
                          <Button
                            style={{ marginBottom: '5px' }}
                            width={120}
                            height={50}
                            text='Add'
                            type='success'
                            onClick={addRequiredCustomerDoc}
                          />
                        </div>
                        <div id='hm-btn-section-1'>
                          <Button
                            style={{ marginBottom: '5px' }}
                            width={120}
                            height={50}
                            text='Edit'
                            type='default'
                            onClick={editRequiredCustomerDoc}
                          />
                        </div>
                        <div id='hm-btn-section-1'>
                          <Button width={120} height={50} text='Remove' type='danger' onClick={removeReqDoc} />
                        </div>
                      </Item>
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={1} />
                        <div>
                          <DataGrid
                            id='req-doc-Grid'
                            dataSource={stReqDocData}
                            defaultFocusedRowIndex={0}
                            showBorders={true}
                            showRowLines={true}
                            showColumnLines={true}
                            focusedRowEnabled={true}
                            hoverStateEnabled={true}
                            onFocusedRowChanged={e => {
                              setRequiredDocData(e.row.data);
                            }}
                          >
                            <Column dataField='id' visible={false} />
                            <Column dataField='usage_advice' caption='Title' width={200} />

                            <Column dataField='comment' caption='Description' allowSorting={false} />
                            <Column dataField='file_name' caption='File Name' width={200} />
                            <Column
                              alignment='center'
                              caption='Signup Person'
                              dataField='signup_person'
                              width={120}
                              cellRender={cellRenderSignupPerson}
                            />
                            <Column
                              alignment='center'
                              caption='Signup Company'
                              dataField='signup_company'
                              width={120}
                              cellRender={cellRenderSignupCompany}
                            />
                          </DataGrid>
                        </div>
                      </Item>
                    </ResponsiveBox>
                  </Item>
                </ResponsiveBox>
              </div>
            </Item>
          </ResponsiveBox>
          <Toast
            visible={toastConfigVisible}
            message='Choose a document first.'
            type='error'
            displayTime={4000}
            onHiding={() => {
              setToastConfigVisible(false);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default PartnerEditReqDoc;
