import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, Item, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './ContactEditPop.css';

const ContactEditComapny = props => {
  const { showPopup, companyid, modeInsert, user, getContactData } = props;
  const companyDataFormRef = useRef(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selCompanyData, setSelCompanyData] = useState({});

  useEffect(() => {
    const fetchSelCompany = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/getcompany/${companyid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(1234, dataArray);
        setSelCompanyData(dataArray[0]);
      } catch (err) {}
    };
    if (showPopup) {
      if (companyid && companyid > 0 && !modeInsert) {
        fetchSelCompany();
      } else {
        setSelCompanyData({});
      }
    }
  }, [companyid, showPopup]);

  const companyData = {
    operation: modeInsert === true ? 1 : 2,
    contactid: selCompanyData.contactid ? selCompanyData.contactid : -1,
    companyid: selCompanyData.companyid ? selCompanyData.companyid : -1,
    contact_typeid: selCompanyData.contact_typeid ? selCompanyData.contact_typeid : 3,
    contact_userid: selCompanyData.contact_userid ? selCompanyData.contact_userid : user.userId,
    contact_portalid: selCompanyData.portalid ? selCompanyData.portalid : user.portalid,
    lastname: selCompanyData.lastname ? selCompanyData.lastname : '',
    email: selCompanyData.email ? selCompanyData.email : '',
    phone_one: selCompanyData.phone_one ? selCompanyData.phone_one : '',
    contact_cat: selCompanyData.contact_cat ? selCompanyData.contact_cat : 85,
    homepage: selCompanyData.homepage ? selCompanyData.homepage : '',
    addressfield: selCompanyData.addressfield ? selCompanyData.addressfield : '',
  };

  return (
    <div id='contact-edit'>
      <Form
        id='contact-edit-company'
        ref={companyDataFormRef}
        formData={companyData}
        labelLocation={'left'}
        colCountByScreen={colCountByScreen}
        validationGroup='Company_Employee'
        onFieldDataChanged={e => {
          if (companyDataFormRef.current) {
            console.log(5, 1, companyData);
            getContactData(companyData);
          }
        }}
      >
        <Item itemType='group' colCount={3}>
          <SimpleItem dataField='lastname' colSpan={3} isRequired={true}>
            <Label text='Company' />
          </SimpleItem>
        </Item>

        <Item itemType='group' colCount={3}>
          <SimpleItem dataField='email' colSpan={3}></SimpleItem>
        </Item>
        <Item itemType='group' colCount={3}>
          <SimpleItem dataField='homepage' colSpan={3}></SimpleItem>
        </Item>
        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='phone_one' colSpan={5}></SimpleItem>
        </Item>
        <EmptyItem />
        <Item itemType='group' colCount={5}>
          <SimpleItem
            dataField='addressfield'
            colSpan={5}
            editorType='dxTextArea'
            editorOptions={{ height: 120, stylingMode: 'outlined' }}
            autoFocus
          >
            <Label text='Address' />
          </SimpleItem>
        </Item>
      </Form>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ContactEditComapny;
