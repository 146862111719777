import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Box, { Item } from 'devextreme-react/box';
import { useHttpClient } from '../../hooks/http-hook';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';

import ArrayStore from 'devextreme/data/array_store';

const PortalPop = props => {
  const { showPopup, hidePopup, portalRowData, addPortal, user } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [featureChanged, setFeatureChanged] = useState(false);
  const srvDataFormRef = useRef(null);
  const [lpPortalTypes, setLpPortalTypes] = useState([]);
  const [partnerDatalist, setPartnerDatalist] = useState([]);
  const [providerDatalist, setProviderDatalist] = useState([]);

  useEffect(() => {
    const fetchPortalTypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/35',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpPortalTypes(dataArray);
      } catch (err) {}
    };

    const fetchfreePartner = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/management/freepartner',
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setPartnerDatalist(dataArray);
      } catch (err) {}
    };

    const fetchfreeProvider = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/management/freeprovider',
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setProviderDatalist(dataArray);
      } catch (err) {}
    };

    if (showPopup) {
      fetchPortalTypes();
      fetchfreePartner();
      fetchfreeProvider();
    }
  }, [sendRequest, showPopup]);

  const stPortalTypes = new ArrayStore({
    key: 'lp_id',
    data: lpPortalTypes,
  });

  const stPartnerDatalist = new ArrayStore({
    key: 'partnerid',
    data: partnerDatalist,
  });

  const stProviderDatalist = new ArrayStore({
    key: 'providerid',
    data: providerDatalist,
  });

  const portalData = {
    portalid: addPortal ? -1 : portalRowData && portalRowData.portalid,
    portaltype: addPortal ? -1 : portalRowData && portalRowData.portaltype,
    portalname: addPortal ? '' : portalRowData && portalRowData.portalname,
    partnerid: addPortal ? -1 : portalRowData && portalRowData.partnerid ? portalRowData.partnerid : -1,
    providerid: addPortal ? -1 : portalRowData && portalRowData.providerid ? portalRowData.providerid : -1,
  };

  const modifyPortal = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportal',
        'POST',
        JSON.stringify({
          portalid: portalData.portalid,
          portaltype: portalData.portaltype,
          portalname: portalData.portalname,
          partnerid: portalData.partnerid,
          providerid: portalData.providerid,
          operation: modify,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const savePopup = async () => {
    if (addPortal) {
      await modifyPortal(1);
    } else {
      await modifyPortal(2);
    }
    await hidePopup(true);
  };

  return (
    <Popup
      title={'Portal'}
      width={600}
      height={300}
      visible={showPopup}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup(true);
      }}
      onShowing={() => {
        setFeatureChanged(false);
      }}
    >
      <Box id='employeeform' direction='col' height='100%'>
        <Item ratio={2} width='100%'>
          <Form id={'form'} ref={srvDataFormRef} formData={portalData} labelLocation={'left'}>
            <Item itemType='group' colCount={6}>
              <SimpleItem dataField='portalname' colSpan={3}>
                <Label location='left' text='Name' />
              </SimpleItem>
              <SimpleItem
                dataField='portaltype'
                editorType='dxSelectBox'
                name='portaltype_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stPortalTypes,
                  valueExpr: 'lp_id',
                  displayExpr: 'lp_value',
                  searchEnabled: false,
                }}
              >
                <Label text='Type' />
              </SimpleItem>
            </Item>
            <EmptyItem />
            <Item itemType='group' colCount={6}>
              <SimpleItem
                dataField='partnerid'
                editorType='dxSelectBox'
                name='partnerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stPartnerDatalist,
                  valueExpr: 'partnerid',
                  displayExpr: 'partnername',
                  searchEnabled: false,
                }}
              >
                <Label text='Merchant' />
              </SimpleItem>
              <SimpleItem
                dataField='providerid'
                editorType='dxSelectBox'
                name='providerid_listbox'
                colSpan={3}
                editorOptions={{
                  dataSource: stProviderDatalist,
                  valueExpr: 'providerid',
                  displayExpr: 'providername',
                  searchEnabled: false,
                }}
              >
                <Label text='Provider' />
              </SimpleItem>
            </Item>
          </Form>
        </Item>
        <Item ratio={1.5}>
          <div id={'closebutton-2'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton-right'
                width={120}
                height={50}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='serviceFeatureValGrp'
                useSubmitBehavior={true}
                onClick={savePopup}
              />
              <Button width={120} height={50} text='Close' type='normal' stylingMode='contained' onClick={hidePopup} />
            </div>
          </div>
        </Item>
      </Box>
    </Popup>
  );
};

export default PortalPop;
