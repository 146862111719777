import React, { useEffect, useRef, useState } from 'react';
import { useHttpClient } from '../../hooks/http-hook';

import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';

import { Form, SimpleItem, EmptyItem, Label, RequiredRule } from 'devextreme-react/form';

const CasesNotesPop = props => {
  const { showPopup, hidePopup, user, entryid, openedToEdit, caseNumber, casesubject } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const popupRef = useRef(null);
  const noteFormRef = useRef(null);
  const [caseData, setCaseData] = useState({});

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/single/${entryid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        setCaseData(dataArray[0]);
      } catch (err) {}
    };

    if (entryid && showPopup && openedToEdit === 2) {
      fetchCases();
    } else if (entryid && showPopup && openedToEdit === 1) {
      setCaseData({});
    }
  }, [sendRequest, entryid, showPopup, openedToEdit]);

  const caseNote = {
    entryid: caseData.entryid || -1,
    case_entryid: caseData.case_entryid || caseNumber,
    userid: caseData.portaluserid || user.id,
    portalid: caseData.portalid || -1,
    notedate: caseData.case_datetime || new Date(),
    subject: caseData.subject || casesubject,
    case_content: caseData.case_content || '',
    portaluser: caseData.portaluser || user.lastname,
  };

  const saveNote = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/cases/createcasenoteown',
        'POST',
        JSON.stringify({
          userid: user.id,
          notesubject: caseNote.subject,
          notecomment: caseNote.case_content,
          case_entryid: caseNote.case_entryid,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = async noteFormRef => {
    const result = noteFormRef.validationGroup.validate();
    if (result.isValid) {
      await saveNote();
      await hidePopup(true);
    } else {
      alert('Please complete all required fields');
    }
  };

  return (
    <Popup
      title={'Case-Note'}
      width={800}
      height={640}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup(openedToEdit === 1);
      }}
    >
      <ResponsiveBox>
        <Row ratio={7} />
        <Row ratio={2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div>
            <Form
              id={'form'}
              ref={noteFormRef}
              formData={caseNote}
              labelLocation={'left'}
              colCountByScreen={colCountByScreen}
              validationGroup='NoteData'
            >
              <Item itemType='group' colCount={1}>
                <SimpleItem dataField='subject' isRequired={true}>
                  <Label text='Subject' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={1}>
                <SimpleItem
                  dataField='case_content'
                  editorType='dxTextArea'
                  isRequired={true}
                  editorOptions={{ height: 300 }}
                >
                  <Label text='Content' />
                </SimpleItem>
              </Item>
              <EmptyItem />
              <Item itemType='group' colCount={3}>
                <SimpleItem dataField='portaluser' editorOptions={{ readOnly: true }}>
                  <Label text='by' />
                </SimpleItem>
              </Item>
            </Form>
          </div>
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'closebutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                visible={openedToEdit === 1}
                width={120}
                height={40}
                text='Save'
                type='default'
                stylingMode='contained'
                validationGroup='NoteData'
                useSubmitBehavior={true}
                onClick={validateForm}
              />
              <Button
                width={120}
                height={40}
                text='close'
                type='normal'
                stylingMode='contained'
                onClick={() => {
                  hidePopup();
                }}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default CasesNotesPop;
