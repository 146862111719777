import React from 'react';

import MultiView from 'devextreme-react/multi-view';
import { Item } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import PortalsTab from './PortalsTab';

const PortalsPage = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Portals</h2>
      <div className={'content-block dx-card'}>
        <PortalsTab user={user} />
      </div>
    </React.Fragment>
  );
};

export default PortalsPage;
