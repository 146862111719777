import React from 'react';

import LookupTab from './LookupTab';

const LookupPage = () => {
  // const location = useLocation(); // locationValue={location}
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Lookup Fields</h2>
      <div className={'content-block dx-card'}>
        <LookupTab />
      </div>
    </React.Fragment>
  );
};

export default LookupPage;
