import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import Button from "devextreme-react/button";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
} from "devextreme-react/data-grid";

import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
} from "devextreme-react/form";

import CaseEditPop from "./CaseEditPop";
import CasesFavoritesPop from "./CasesFavoritesPop";

const getscreenheight = () => {
  const anValue = window.innerHeight - 700;
  // console.log(5678, anValue);
  return anValue;
};

const CasesOneTab = (props) => {
  const {
    user,
    selPartnerPortal,
    passToParent,
    selnewskey,
    dataStCaseType,
    dataStCaseStates,
    dataStDevPrios,
    getReload,
    reloadGrid,
    dataStCaseSituation,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [casesDataList, setCasesDatalist] = useState([]);
  const [casesGridData, setCasesGridData] = useState([]);
  const [selEntryid, setSelEntryid] = useState(0);
  const [showCasePopup, setShowCasePopup] = useState(false);
  const [openedType, setOpenedType] = useState(1);
  const [reloadContacts, setReloadContacts] = useState(false);
  const [clientSearchText, setClientSearchText] = useState("");

  const casesOneGrid = useRef(null);
  const [compDay, setCompDay] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [userCaseGrpsData, setUserCaseGrpsData] = useState([]);

  const [showFavoritesPopup, setShowFavoritesPopup] = useState(false);

  useEffect(() => {
    // console.log(9876, selnewskey);
    selnewskey && setSelEntryid(selnewskey);
  }, [selnewskey]);

  useEffect(() => {
    if (showCasePopup === false && clientSearchText.length === 0) {
      const interval = setInterval(async () => {
        // console.log(1111, showCasePopup);
        try {
          const dataArray = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + `/cases/all/${user.portalid}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + user.token,
            }
          );
          await setCasesDatalist(dataArray);
        } catch (err) {}
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [user.portalid, showCasePopup, clientSearchText]);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/cases/all/${user.portalid}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setCasesDatalist(dataArray);
      } catch (err) {}
    };
    if (user.portalid) {
      fetchCases();
    }
  }, [sendRequest, reloadGrid, user.portalid]);

  const fetchSearchCase = async (field1, field2, field3) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/cases/casesearch",
        "POST",
        JSON.stringify({
          field1: "%" + field1 + "%",
          field2: "%" + field2 + "%",
          field3: "%" + field3 + "%",
          portalid: user.portalid,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        }
      ).then((data) => {
        setCasesDatalist(data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchUserCaseGrps = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/cases/usercasegroups/${user.userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        setUserCaseGrpsData(dataArray);
      } catch (err) {}
    };
    if (user.userId) {
      fetchUserCaseGrps();
    }
  }, [sendRequest, user.userId]);

  const cleanfield = (anfield) => {
    let resultfield = anfield.replace("+", "");
    resultfield = resultfield.replace("-", "");
    resultfield = resultfield.replace("(", "");
    resultfield = resultfield.replace(")", "");
    resultfield = resultfield.replace(";", "");
    resultfield = resultfield.replace(",", "");
    return resultfield;
  };

  const doSearch = (anSearchText) => {
    let field1 = anSearchText.split(" ")[0] ? anSearchText.split(" ")[0] : "";
    let field2 = anSearchText.split(" ")[1] ? anSearchText.split(" ")[1] : "";
    let field3 = anSearchText.split(" ")[2] ? anSearchText.split(" ")[2] : "";

    field1 = cleanfield(field1);
    field2 = cleanfield(field2);
    field3 = cleanfield(field3);

    fetchSearchCase(field1, field2, field3);
  };

  const navigateToCasePage = () => {
    setOpenedType(2);
    setShowCasePopup(true);
  };

  const navigateToAddNewCasePage = () => {
    setOpenedType(1);
    setShowCasePopup(true);
  };

  const renderGridCell = (cellData) => {
    const gDay = cellData.value.toISOString().split("T")[0];
    // console.log(111, compDay, gDay);
    if (compDay === gDay) {
      return <div style={{ color: "blue" }}>{cellData.text}</div>;
    } else {
      return <div style={{ color: "default" }}>{cellData.text}</div>;
    }
  };

  const hideCasePopup = useCallback((doContactReload) => {
    setShowCasePopup(false);
    console.log(doContactReload);
    if (doContactReload) {
      setReloadContacts(!reloadContacts);
      getReload(true);
    }
  });

  const buttonAttributes = {
    text: "Search",
    type: "normal",
    icon: "search",
    height: 36,
    useSubmitBehavior: true,
  };

  const handleSubmit = (e) => {
    const searchtext = e.target.elements.searchtext.value;
    setClientSearchText(searchtext);
    doSearch(searchtext);
    e.preventDefault();
  };

  const openFavoritesPopup = () => {
    setShowFavoritesPopup(true);
  }

  const hideFavoritesPopup = () => {
    setShowFavoritesPopup(false);
  }

  return (
    <React.Fragment>
      <CaseEditPop
        showPopup={showCasePopup}
        hidePopup={hideCasePopup}
        user={user}
        entryid={selEntryid}
        openedToEdit={openedType}
        dstCaseType={dataStCaseType}
        dstCaseStates={dataStCaseStates}
        dstStDevPrios={dataStDevPrios}
        dstStCaseSituation={dataStCaseSituation}
      />
      <CasesFavoritesPop 
        showPopup={showFavoritesPopup}
        hidePopup={hideFavoritesPopup}
        user={user}
      />
      <ResponsiveBox>
        <Col ratio={0.4} screen="md lg sm" />
        <Col ratio={2.3} />
        <Row ratio={0.2} />
        <Row ratio={3} />
        <Item>
          <Location screen="md lg sm" row={0} col={0} colspan={2} />

          <ResponsiveBox>
            <Row ratio={1} />
            <Col ratio={1} />
            <Col ratio={2} />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <form
                action="search"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Form id="client-search-section" labelLocation="left">
                  <GroupItem cssClass="first-group" colCount={3}>
                    <SimpleItem dataField="searchtext" colSpan={3}>
                      <Label text="Cases Search" />
                    </SimpleItem>
                  </GroupItem>
                </Form>
              </form>
            </Item>
            <Item>
              <Location screen="md lg sm xs" row={0} col={1} />
              <Form id="favorites-section" labelLocation="left">
                <GroupItem cssClass="first-group" colCount={15}>
                  <SimpleItem
                    colSpan={7}
                    dataField="userid"
                    editorType="dxSelectBox"
                    name="user_listbox"
                    editorOptions={{
                      // dataSource: caluser,
                      valueExpr: "userid",
                      displayExpr: "text",
                      searchEnabled: true,
                    }}
                  >
                    <Label text="Favorites" />
                  </SimpleItem>

                  <SimpleItem
                    colSpan={2}
                    editorType="dxCheckBox"
                    horizontalAlignment="left"
                    dataField={"enableNotification"}
                    editorOptions={{
                      // value: isEnableNotification(),
                      onValueChanged: (e) => {
                        //setFormData({
                        //   ...formData,
                        //   enableNotification: e.value
                        //  });
                      },
                      width: 30,
                    }}
                    label={{
                      text: "Show",
                      alignment: "left",
                      location: "right",
                      showColon: false,
                    }}
                  />
                  <ButtonItem
                    colSpan={5}
                    horizontalAlignment="right"
                    buttonOptions={{
                      text: "Define",
                      type: "normal",
                      // icon: "search",
                      width: 110,
                      height: 36,
                      onClick: openFavoritesPopup
                    }}
                  />
                </GroupItem>
              </Form>
            </Item>
          </ResponsiveBox>
        </Item>
        <Item>
          <Location screen="md lg sm" row={1} col={0} />
          <div id="hm-btn-section-1">
            <Button
              width={120}
              height={36}
              text="Add"
              type="default"
              onClick={navigateToAddNewCasePage}
            />
          </div>
          <div id="hm-btn-section-11">
            <Button
              width={120}
              height={36}
              text="Open"
              type="success"
              onClick={navigateToCasePage}
            />
          </div>
        </Item>

        <Item>
          <Location screen="md lg sm" row={1} col={1} />
          <Location screen="xs" row={0} col={0} />

          <div>
            <DataGrid
              id="casesGrid"
              height={getscreenheight}
              ref={casesOneGrid}
              dataSource={casesDataList}
              keyExpr="entryid"
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              focusedRowEnabled={true}
              showBorders={true}
              showRowLines={true}
              showColumnLines={true}
              autoNavigateToFocusedRow={true}
              focusedRowKey={selEntryid.toString()}
              onFocusedRowChanged={(e) => {
                if (e.row) {
                  if (e.row.key !== selEntryid) {
                    setSelEntryid(e.row.key);
                    setCasesGridData(e.row.data);
                    passToParent(
                      e.row.key,
                      e.row.data.subject,
                      e.row.data.case_content,
                      e.row.data.contactid
                    );
                  }
                }
              }}
              onRowDblClick={() => {
                navigateToCasePage();
              }}
            >
              <FilterRow visible={false} />
              <Column dataField="entryid" visible={false} />
              <Column dataField="portaluserid" visible={false} />
              <Column dataField="portalid" visible={false} />
              <Column
                dataField="lastnote_datetime"
                caption="Last Note"
                dataType="datetime"
                width={130}
                cellRender={renderGridCell}
              ></Column>
              <Column
                dataField="clientname"
                caption="Contact"
                width={200}
              ></Column>
              <Column dataField="subject" caption="Subject"></Column>
              <Column
                dataField="casetype"
                width={200}
                caption="Section"
              ></Column>
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default CasesOneTab;
