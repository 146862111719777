import React from 'react';

import MultiView from 'devextreme-react/multi-view';
import { Item } from 'devextreme-react/responsive-box';

import { useAuth } from '../../contexts/auth';

import MailTemplatesTab from './MailTemplatesTab';

const MailTemplatesPage = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <h2 className={'content-block'}>E-Mail Templates</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={' wide-card'}>
          <MultiView height={650} loop={false}>
            <Item title='Portals'>
              <MailTemplatesTab user={user} />
            </Item>
          </MultiView>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailTemplatesPage;
