import React from 'react';

import { useAuth } from '../../contexts/auth';

import OpTemplatesTab from './OpTemplatesTab';

const OpTemplatesPage = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Operation Templates</h2>

      <div className={'content-block dx-card'}>
        <OpTemplatesTab user={user} />
      </div>
    </React.Fragment>
  );
};

export default OpTemplatesPage;
