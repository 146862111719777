import React, { useEffect, useState } from "react";
import DataGrid, {
  Scrolling,
  Column,
  FilterRow,
} from "devextreme-react/data-grid";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

const getscreenheight = () => {
  const anValue = window.innerHeight - 320;
  // console.log(5678, anValue);
  return anValue;
};

const CasesNews = (props) => {
  const {
    user,
    selPartnerPortal,
    reloadNewsGrid,
    reloadNewsGridCondition,
    getNewsEntryIds,
  } = props;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [casesNewsData, setCasesNewsData] = useState([]);
  const [newsGridKey, setNewsGridKey] = useState(0);
  const [dashCalData, setDashCalData] = useState([]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    const fetchCasesNews = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/cases/casesnews/${selPartnerPortal}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        await setCasesNewsData(dataArray);

        await delay(50);

        if (reloadNewsGrid === true) {
          await reloadNewsGridCondition(false);
        }
      } catch (err) {}
    };

    const fetchCalendarEntries = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/calendar/crmdashcalentries/${selPartnerPortal}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        await setDashCalData(dataArray);
      } catch (err) {}
    };

    if (selPartnerPortal) {
      fetchCasesNews();
      fetchCalendarEntries();
    }
  }, [sendRequest, reloadNewsGrid, selPartnerPortal]);

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            `/cases/casesnews/${selPartnerPortal}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + user.token,
          }
        );
        await setCasesNewsData(dataArray);
      } catch (err) {}
    }, 15000);
    return () => clearInterval(interval);
  }, [selPartnerPortal]);

  const stCasesNews = new ArrayStore({
    key: "entryid",
    data: casesNewsData,
  });

  const dcCasesNews = new DataSource({
    store: stCasesNews,
  });

  const stDashCalData = new ArrayStore({
    key: "entryid",
    data: dashCalData,
  });

  const dcDashCalData = new DataSource({
    store: stDashCalData,
  });

  const focusedRowChange = (e) => {
    const anEntryid = parseInt(e.row.key);
    const anCaseEntryId = parseInt(e.row.data.case_entryid);
    const anContactId = parseInt(e.row.data.contactid);
    getNewsEntryIds(anEntryid, anCaseEntryId, anContactId);
  };

  return (
    <React.Fragment>
      <div>
        <DataGrid
          id="cases-news-grid"
          height={getscreenheight}
          dataSource={dcCasesNews}
          defaultFocusedRowIndex={0}
          showBorders={true}
          focusedRowEnabled={true}
          showRowLines={true}
          wordWrapEnabled={true}
          onFocusedRowChanged={focusedRowChange}
        >
          {/*  <Scrolling mode='virtual' />
           */}

          <Column dataField="entryid" visible={false}></Column>
          <Column dataField="case_entryid" visible={false}></Column>
          <Column
            dataField="newsdata"
            caption="Notes"
            encodeHtml={false}
            dataType="string"
          ></Column>
        </DataGrid>
      </div>
      <DataGrid
        id="cases-cal-grid"
        dataSource={dcDashCalData}
        // defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        showBorders={true}
        showColumnHeaders={true}
        // focusedRowEnabled={true}
      >
        <Column dataField="entryid" visible={false} />
        <Column dataField="username" visible={false} width="150px" />
        <Column dataField="calevent" visible={true} caption="Absence, Duty" />
        <Column
          dataField="startdate"
          visible={true}
          width="90px"
          caption="From"
        />
        <Column
          dataField="enddate"
          visible={true}
          width="90px"
          caption="Until"
        />
      </DataGrid>
    </React.Fragment>
  );
};

export default CasesNews;
