import React from 'react';
import './portaluser.css';

import PortalUserTab from './PortalUserTab';

const SettingsPage = () => {
  // const location = useLocation(); // locationValue={location}
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Users</h2>
      <div className={'content-block dx-card'}>
        <PortalUserTab />
      </div>
    </React.Fragment>
  );
};

export default SettingsPage;
