import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem, Item, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import './ContactEditPop.css';

const ContactEditComapnyEmployees = props => {
  const { showPopup, companyid, modeInsert, user } = props;
  const dataFormRef = useRef(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [companyEmployees, setCompanyEmployees] = useState([]);
  const [selCompanyEmployee, setSelCompanyEmployee] = useState({});
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    const fetchSelCompanyEmployees = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/contacts/getcompanyemployees/${companyid}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + user.token,
          }
        );
        console.log(5678, dataArray);
        setCompanyEmployees(dataArray);
      } catch (err) {}
    };
    if (showPopup) {
      if (companyid && companyid > 0 && !modeInsert) {
        fetchSelCompanyEmployees();
      } else {
        setCompanyEmployees([]);
        setSelCompanyEmployee({});
      }
    }
  }, [companyid, reloadData, showPopup]);

  const stCompanyEmployees = new ArrayStore({
    key: 'contactid',
    data: companyEmployees,
  });

  const employeeData = {
    contactid: selCompanyEmployee.contactid,
    companyid: selCompanyEmployee.companyid,
    contact_typeid: selCompanyEmployee.contact_typeid,
    contact_userid: selCompanyEmployee.contact_userid,
    portalid: selCompanyEmployee.portalid,
    lastname: selCompanyEmployee.lastname,
    firstname: selCompanyEmployee.firstname,
    salutation: selCompanyEmployee.salutation,
    email: selCompanyEmployee.email,
    phone_one: selCompanyEmployee.phone_one,
    contact_cat: selCompanyEmployee.contact_cat,
    jobtitle: selCompanyEmployee.jobtitle,
  };

  const modifyEmployee = async modify => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/contacts/modemployee',
        'POST',
        JSON.stringify({
          operation: modify,
          contactid: employeeData.contactid,
          companyid: employeeData.companyid,
          contact_typeid: employeeData.contact_typeid,
          contact_userid: employeeData.contact_userid,
          portalid: employeeData.portalid,
          lastname: employeeData.lastname,
          firstname: employeeData.firstname,
          salutation: employeeData.salutation,
          email: employeeData.email,
          phone_one: employeeData.phone_one,
          contact_cat: employeeData.contact_cat,
          jobtitle: employeeData.jobtitle,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setReloadData(!reloadData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id='contact-edit'>
      <Form id={'form'} formData={employeeData} validationGroup='Company_Employee' labelLocation={'left'}>
        <Item itemType='group'>
          <DataGrid
            id='emplpyeegrid'
            dataSource={stCompanyEmployees}
            focusedRowEnabled={true}
            defaultFocusedRowIndex={0}
            showBorders={true}
            showRowLines={false}
            showColumnLines={false}
            showColumnHeaders={true}
            onFocusedRowChanged={e => {
              if (e) {
                setSelCompanyEmployee(e.row.data);
              }
            }}
          >
            <Column dataField='contactid' visible={false} />
            <Column dataField='lastname' caption='Employees' width={150} />
            <Column dataField='firstname' caption='' width={120} />
          </DataGrid>
        </Item>
        <Item itemType='group'>
          <SimpleItem dataField='lastname'>
            <Label text='Last Name' />
          </SimpleItem>
        </Item>
        <Item itemType='group' colCount={3}>
          <SimpleItem dataField='firstname' colSpan={2}>
            <Label text='First Name' />
          </SimpleItem>
          <SimpleItem dataField='salutation' colSpan={1}>
            <Label text=' ' />
          </SimpleItem>
        </Item>

        <Item itemType='group'>
          <SimpleItem dataField='email' />
        </Item>
        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='phone_one' colSpan={4}>
            <Label text='Phone' />
          </SimpleItem>
        </Item>

        <Item itemType='group' colCount={5}>
          <SimpleItem dataField='jobtitle' colSpan={4}>
            <Label text='Position' />
          </SimpleItem>
        </Item>
        <EmptyItem />
        <Item itemType='group' colCount={3}>
          <ButtonItem
            horizontalAlignment='left'
            colSpan={1}
            buttonOptions={{
              text: 'Add',
              width: 150,
              height: 36,
              type: 'default',
              stylingMode: 'contained',
              onClick: () => {
                modifyEmployee(1);
              },
            }}
          />
          <ButtonItem
            horizontalAlignment='left'
            colSpan={1}
            buttonOptions={{
              text: 'Save',
              width: 150,
              height: 36,
              type: 'success',
              stylingMode: 'contained',
              onClick: () => {
                modifyEmployee(2);
              },
            }}
          />
          <ButtonItem
            horizontalAlignment='left'
            colSpan={1}
            buttonOptions={{
              text: 'Remove',
              width: 150,
              height: 36,
              type: 'danger',
              stylingMode: 'contained',
              onClick: () => {
                modifyEmployee(3);
              },
            }}
          />
        </Item>
      </Form>
    </div>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

export default ContactEditComapnyEmployees;
