import React, { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import ArrayStore from "devextreme/data/array_store";

import "./ClientsApprovedPage.css";

import {
  Form,
  SimpleItem,
  EmptyItem,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import { Tabs, MultiView, SelectBox } from "devextreme-react";
import { useAuth } from "../../contexts/auth";
import { Label } from "devextreme-react/data-grid";

import ContactOneTab from "./ContactOneTab";
import ContactDetails from "./ContactDetails";

const ContactsPage = () => {
  const { user } = useAuth();
  const [selectedKey, setSelectedKey] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [reloadNewsGrid, setReloadNewsGrid] = useState(false);

  const getContactid = useCallback((fPersonid) => {
    setSelectedKey(fPersonid);
  });

  const reloadNewsGridCondition = async (anValue) => {
    await setReloadNewsGrid(anValue);
  };

  return (
    <React.Fragment>
      {/* <h2 className={'content-block'}>Cases</h2> */}
      <div className={"content-block"}>
        <div className={"dx-card wide-card"}>
          <ResponsiveBox>
            <Row />
            <Col />
            <Item>
              <Location screen="md lg sm xs" row={0} col={0} />
              <div className="operation-page-content">
                <div className="operation-page-content-container">
                  <ResponsiveBox>
                    <Row />
                    <Col />
                    <Item>
                      <Location screen="md lg sm xs" row={0} col={0} />

                      <ContactOneTab passToParent={getContactid} user={user} />

                      <div style={{ flexDirection: "column" }}>
                        <div id="operations-notes-tab">
                          <Tabs
                            selectedIndex={tabIndex}
                            width="100%"
                            // height='20px'
                            visible={true}
                            onItemClick={(e) => {
                              setTabIndex(e.itemIndex);
                            }}
                          >
                            <Item text="Contact" />
                            <Item text="" />
                          </Tabs>
                          <MultiView
                            height={400}
                            animationEnabled={false}
                            selectedIndex={tabIndex}
                          >
                            <Item title="Client Details">
                              <ContactDetails
                                contactid={selectedKey}
                                user={user}
                                reloadNewsGridCondition={
                                  reloadNewsGridCondition
                                }
                              />
                            </Item>
                          </MultiView>
                        </div>
                      </div>
                    </Item>
                  </ResponsiveBox>
                </div>
              </div>
            </Item>
          </ResponsiveBox>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactsPage;
