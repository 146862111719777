import React, { useRef, useState, useEffect } from 'react';
import './Portals.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column, Editing, Lookup } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';

import Button from 'devextreme-react/button';

import PortalPop from './PortalPop';
import MessagePop from '../../ui_elements/MessagePop';

const PortalsTab = props => {
  const { user } = props;
  const portalDataGrid = useRef(null);
  const [portalsDatalist, setPortalsDatalist] = useState([]);

  const [portalGridData, setPortalGridData] = useState();

  const [showPortalPopup, setShowPortalPopup] = useState(false);
  const [addPortal, setAddPortal] = useState(false);
  const [showMessagePop, setShowMessagePop] = useState(false);
  const [reloadPortalData, setReloadPortalData] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const portalData = {
    portalid: portalGridData && portalGridData.portalid,
    portaltype: portalGridData && portalGridData.portaltype,
    portalname: portalGridData && portalGridData.portalname,
    partnerid: portalGridData && portalGridData.partnerid ? portalGridData.partnerid : -1,
    providerid: portalGridData && portalGridData.providerid ? portalGridData.providerid : -1,
  };

  useEffect(() => {
    const fetchPortals = async () => {
      try {
        const dataArray = await sendRequest(process.env.REACT_APP_BACKEND_URL + '/management/portals', 'GET', null, {
          Authorization: 'Bearer ' + user.token,
        });
        setPortalsDatalist(dataArray);
      } catch (err) {}
    };

    fetchPortals();
  }, [sendRequest, reloadPortalData]);

  const stPortalData = new ArrayStore({
    key: 'portalid',
    data: portalsDatalist,
  });

  const delPortal = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/management/modportal',
        'POST',
        JSON.stringify({
          portalid: portalData.portalid,
          operation: 3,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addNewPortal = () => {
    setAddPortal(true);
    setShowPortalPopup(true);
  };

  const editExistPortal = () => {
    setAddPortal(false);
    setShowPortalPopup(true);
  };

  const removePortal = () => {
    setShowMessagePop(true);
  };

  const hidePortalPopup = () => {
    setShowPortalPopup(false);
    setReloadPortalData(!reloadPortalData);
  };

  const hideMessagePop = doRemove => {
    setShowMessagePop(false);
    if (doRemove) {
      delPortal();
      setReloadPortalData(!reloadPortalData);
    }
  };

  return (
    <React.Fragment>
      <MessagePop
        showPopup={showMessagePop}
        hidePopup={hideMessagePop}
        popTitle='Confirmation'
        popMessage='Delete the selected Portal?'
      />
      <PortalPop
        showPopup={showPortalPopup}
        hidePopup={hidePortalPopup}
        portalRowData={portalGridData}
        addPortal={addPortal}
        user={user}
      />
      <ResponsiveBox>
        <Row ratio={1} />
        <Col ratio={1.5} />
        <Col ratio={10} />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          <div id='portalbutton'>
            <Button width={100} height={50} text='Add' type='default' stylingMode='contained' onClick={addNewPortal} />
            <div id='sysbutton-2'>
              <Button
                width={100}
                height={50}
                text='Edit'
                type='success'
                stylingMode='contained'
                onClick={editExistPortal}
              />
            </div>
            <div id='sysbutton-2'>
              <Button
                width={100}
                height={50}
                text='Remove'
                type='danger'
                stylingMode='contained'
                onClick={removePortal}
              />
            </div>
          </div>
        </Item>

        <Item>
          <Location screen='md lg sm xs' row={0} col={1} />
          <div id='portal-data-grid-box'>
            <DataGrid
              // id='portal-data-grid'
              ref={portalDataGrid}
              dataSource={stPortalData}
              showRowLines={true}
              showBorders={true}
              showColumnLines={true}
              columnAutoWidth={true}
              defaultFocusedRowIndex={0}
              focusedRowEnabled={true}
              showColumnHeaders={true}
              onFocusedRowChanged={e => {
                setPortalGridData(e.row.data);
              }}
            >
              <Column dataField='portalid' visible={true} caption='ID' width='50'></Column>
              <Column dataField='partnerid' visible={false}></Column>
              <Column dataField='providerid' visible={false}></Column>
              <Column dataField='partnertype' visible={false}></Column>
              <Column dataField='portalname' caption='Name' width='250'></Column>
              <Column dataField='portaltypetxt' caption='Type' width='200' />
              <Column dataField='partnername' caption='Merchant' width='200' />
              <Column dataField='providername' caption='Provider' width='200' />
            </DataGrid>
          </div>
        </Item>
      </ResponsiveBox>
    </React.Fragment>
  );
};

export default PortalsTab;
