import React, { useCallback, useEffect, useRef, useState } from 'react';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Form, SimpleItem, EmptyItem, Label, RequiredRule, ButtonItem } from 'devextreme-react/form';
import MultiView from 'devextreme-react/multi-view';
import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import './ContactEditPop.css';

import ContactEditComapny from './ContactEditCompany';
import ContactEditComapnyEmployees from './ContactEditCompanyEmployees';
import ContactEditEmployees from './ContactEditEmployee.js';
import ContactEditOther from './ContactEditOther';

const ContactEditPop = props => {
  const { showPopup, hidePopup, user, selcontactid, modeInsert, contactType } = props;

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selContactData, setSelContactData] = useState({});
  const popupRef = useRef(null);

  const [contactsChanged, setContactsChanged] = useState(false);

  let cntContactData = {};

  function navigateToHome() {
    hidePopup(contactsChanged);
  }

  const getContactData = useCallback(cmpData => {
    cntContactData = cmpData;
    console.log(9, cntContactData);
  });

  const modifyContact = async cntctData => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/contacts/modemployee',
        'POST',
        JSON.stringify({
          operation: cntctData.operation,
          contactid: cntctData.contactid,
          companyid: cntctData.companyid,
          contact_typeid: cntctData.contact_typeid,
          contact_userid: cntctData.contact_userid,
          portalid: cntctData.portalid,
          lastname: cntctData.lastname,
          firstname: cntctData.firstname,
          salutation: cntctData.salutation,
          email: cntctData.email,
          phone_one: cntctData.phone_one,
          contact_cat: cntctData.contact_cat,
          homepage: cntctData.homepate,
          addressfield: cntctData.addressfield,
          jobtitle: cntctData.jobtitle,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      await setContactsChanged(true);
      hidePopup(contactsChanged);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Popup
      title={'Contact'}
      width={contactType === 3 ? (modeInsert === true ? 500 : 1000) : 800}
      height={contactType === 3 ? (modeInsert === true ? 550 : 720) : contactType === 1 ? 640 : 500}
      ref={popupRef}
      visible={showPopup}
      resizeEnabled={true}
      onHiding={() => {
        hidePopup(contactsChanged);
      }}
      onShowing={() => {
        setContactsChanged(false);
      }}
    >
      <ResponsiveBox>
        <Row ratio={8} />
        <Row ratio={2} />
        <Col />
        <Item>
          <Location screen='md lg sm xs' row={0} col={0} />
          {contactType === 3 && (
            // Company
            <div id='contact-edit'>
              <ResponsiveBox>
                <Col ratio={1} />
                {!modeInsert && <Col ratio={1} />}
                <Row ratio={1} />
                <Row ratio={1} />
                <Item>
                  <Location screen='md lg sm xs' row={0} col={0} />
                  <ContactEditComapny
                    showPopup={showPopup}
                    companyid={selcontactid}
                    user={user}
                    modeInsert={modeInsert}
                    getContactData={getContactData}
                  />
                </Item>
                {!modeInsert && (
                  <Item>
                    <Location screen='md lg' row={0} col={1} />
                    <Location screen='sm xs' row={1} col={0} />
                    <ResponsiveBox id='contact-edit-employee'>
                      <Col ratio={1} />
                      <Row ratio={1} />
                      <Item>
                        <Location screen='md lg sm xs' row={0} col={0} />
                        <ContactEditComapnyEmployees
                          showPopup={showPopup}
                          companyid={selcontactid}
                          user={user}
                          modeInsert={modeInsert}
                        />
                      </Item>
                    </ResponsiveBox>
                  </Item>
                )}
              </ResponsiveBox>
            </div>
          )}
          {contactType === 2 && (
            // Employee
            <div id='contact-edit'>
              <ResponsiveBox>
                <Col ratio={1} />
                <Row ratio={1} />
                <Item>
                  <Location screen='md lg sm xs' row={0} col={0} />
                  <ContactEditEmployees
                    showPopup={showPopup}
                    contactid={selcontactid}
                    modeInsert={modeInsert}
                    user={user}
                    getContactData={getContactData}
                  />
                </Item>
              </ResponsiveBox>
            </div>
          )}
          {contactType === 1 && (
            // Other
            <div id='contact-edit'>
              <ResponsiveBox>
                <Col ratio={1} />
                <Row ratio={1} />
                <Item>
                  <Location screen='md lg sm xs' row={0} col={0} />
                  <ContactEditOther
                    showPopup={showPopup}
                    contactid={selcontactid}
                    modeInsert={modeInsert}
                    user={user}
                    getContactData={getContactData}
                  />
                </Item>
              </ResponsiveBox>
            </div>
          )}
        </Item>
        <Item>
          <Location screen='md lg sm xs' row={1} col={0} />
          <div id={'contactpopbutton'}>
            <div style={{ flexDirection: 'row' }}>
              <Button
                id='savebutton'
                width={120}
                height={41}
                text='Save'
                type='default'
                stylingMode='contained'
                // validationGroup='contactDataVal'
                // useSubmitBehavior={true}
                onClick={() => {
                  modifyContact(cntContactData);
                }}
              />
              <Button
                width={120}
                height={41}
                text='Close'
                type='normal'
                stylingMode='contained'
                onClick={navigateToHome}
              />
            </div>
          </div>
        </Item>
      </ResponsiveBox>
    </Popup>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
};

const baseEditorOptions = {};

export default ContactEditPop;
