import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List, { MenuItem } from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import { TabPanel } from 'devextreme-react';

const AlertPanel = props => {
  const { menuMode, alertMessages, cntMessages, user } = props;

  const history = useHistory();

  const handleAlert = alertData => {
    if (alertData.inquiryid === '-1') {
      openCaseNote(alertData.omentryid);
    } else {
      openInquriy(alertData.inquiryid);
    }
  };

  const menuItems = alerts => {
    const anlist = [];
    alerts.map((aItem, index) => {
      anlist.push({
        key: index,
        text: aItem.alert_from + ': ' + aItem.alert_message,
        onClick: () => {
          handleAlert(aItem);
        },
      });
    });
    return anlist;
  };

  const openInquriy = anInquiryid => {
    user.inquiry = {
      selInquiryId: anInquiryid,
      selClientid: '',
    };
    history.push({
      pathname: '/inquiry',
    });
  };

  const openCaseNote = entryid => {
    user.casenote = {
      selEntryid: entryid,
    };
    history.push({
      pathname: '/casenote',
    });
  };

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        {cntMessages || cntMessages !== 0 ? <div className={'dx-badge'}>{cntMessages} New Message</div> : ''}
      </div>
      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems(alertMessages)}
          target={'.alert-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
    </div>
  );
};

export default AlertPanel;
