import React, { useRef, useState, useEffect } from 'react';
import './lookup.css';

import { useHttpClient } from '../../hooks/http-hook';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { useAuth } from '../../contexts/auth';
import ResponsiveBox, { Row, Col, Item, Location } from 'devextreme-react/responsive-box';
import Button from 'devextreme-react/button';

import { Form, SimpleItem, Label, ButtonItem } from 'devextreme-react/form';

const LookupTab = () => {
  const { user } = useAuth();
  const [lookupTypeGridData, setLookupTypeGridData] = useState({});
  const [lookupEntriesGridData, setLookupEntriesGridData] = useState({});
  const [lpLookupDataEntries, setLpLookupDataEntries] = useState([]);
  const [selectedType, setSelectedType] = useState(-1);
  const [lookupEntriesDataMod, setLookupEntriesDataMod] = useState(false);

  const [lpLookupTypes, setLpLookupTypes] = useState([]);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const lookupTypeData = {
    lp_type: (lookupTypeGridData && lookupTypeGridData.lp_type) || -1,
    lp_id: lookupTypeGridData && lookupTypeGridData.lp_id,
    lp_value: lookupTypeGridData && lookupTypeGridData.lp_value,
  };

  const lookupEntriesData = {
    lp_type: (lookupEntriesGridData && lookupEntriesGridData.lp_type) || selectedType,
    lp_id: lookupEntriesGridData && lookupEntriesGridData.lp_id,
    lp_value: lookupEntriesGridData && lookupEntriesGridData.lp_value,
    short_id: lookupEntriesGridData && lookupEntriesGridData.short_id,
  };

  useEffect(() => {
    const fetchLookupTypes = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/base/getlookupvalues/-1',
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLpLookupTypes(dataArray);
      } catch (err) {}
    };
    fetchLookupTypes();
  }, [sendRequest, lookupEntriesDataMod]);

  const stLpLookupTypes = new ArrayStore({
    key: ['lp_type', 'lp_id'],
    data: lpLookupTypes,
  });

  useEffect(() => {
    const fetchLookupData = async () => {
      try {
        const dataArray = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/base/getlookupvalues/${selectedType}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + user.token }
        );
        setLookupEntriesGridData({});
        setLpLookupDataEntries(dataArray);
      } catch (err) {}
    };
    if (selectedType && selectedType > -1) {
      fetchLookupData();
    }
  }, [sendRequest, selectedType, lookupEntriesDataMod]);

  const stLpLookupDataEntries = new ArrayStore({
    key: 'lp_id',
    data: lpLookupDataEntries,
  });

  const modLookupData = async (modifyOperation, lpData) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/base/lookupdata',
        'POST',
        JSON.stringify({
          lp_type: lpData.lp_type,
          lp_id: lpData.lp_id,
          lp_value: lpData.lp_value,
          short_id: lpData.short_id,
          operation: modifyOperation,
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user.token }
      );
      setLookupEntriesDataMod(!lookupEntriesDataMod);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ResponsiveBox>
      <Row ratio={1} />
      <Row ratio={6} />
      <Item>
        <Location screen='md lg sm xs' row={0} col={0} />
        <div id='lp-companytab-box'>
          <ResponsiveBox>
            <Row />
            <Col ratio={0.7} />
            <Col ratio={2.7} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Col ratio={1} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <Form id={'form'} formData={lookupTypeData} labelLocation={'left'}>
                <SimpleItem dataField='lp_id'>
                  <Label location='left' text='ID' />
                </SimpleItem>
              </Form>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <Form id={'form'} formData={lookupTypeData} labelLocation={'left'}>
                <SimpleItem dataField='lp_value'>
                  <Label location='left' text='Section' />
                </SimpleItem>
              </Form>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={2} />
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Add'
                type='default'
                onClick={() => {
                  modLookupData(1, lookupTypeData);
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={3} />
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Change'
                type='success'
                onClick={() => {
                  modLookupData(2, lookupTypeData);
                }}
              />
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={4} />
              <Button
                id='companytab-button'
                width={98}
                height={'36px'}
                text='Remove'
                type='danger'
                onClick={() => {
                  modLookupData(3, lookupTypeData);
                }}
              />
            </Item>
          </ResponsiveBox>
        </div>
      </Item>
      <Item>
        <Location screen='md lg sm xs' row={1} col={0} />
        <div id='lp-companytab-box-grid'>
          <ResponsiveBox>
            <Row />
            <Col ratio={2} />
            <Col ratio={5} />
            <Item>
              <Location screen='md lg sm xs' row={0} col={0} />
              <DataGrid
                id='lp-type-tab-grid'
                dataSource={stLpLookupTypes}
                showRowLines={true}
                showBorders={true}
                showColumnLines={true}
                columnAutoWidth={true}
                defaultFocusedRowIndex={0}
                focusedRowEnabled={true}
                showColumnHeaders={false}
                onFocusedRowChanged={e => {
                  setLookupTypeGridData(e.row.data);
                  setSelectedType(e.row.data.lp_id);
                }}
              >
                <Column dataField='lp_type' visible={false}></Column>

                <Column dataField='lp_id' caption='ID'></Column>
                <Column dataField='lp_value' caption='Value'></Column>
              </DataGrid>
            </Item>
            <Item>
              <Location screen='md lg sm xs' row={0} col={1} />
              <Form id='form-lptype-tab-data' formData={lookupEntriesData} labelLocation={'left'}>
                <Item itemType='group' colCount={12}>
                  <SimpleItem dataField='lp_id'>
                    <Label text='ID' />
                  </SimpleItem>
                  <SimpleItem dataField='lp_value' colSpan={3}>
                    <Label text='Entry' />
                  </SimpleItem>
                  <SimpleItem dataField='short_id' colSpan={2}>
                    <Label text='Short ID' />
                  </SimpleItem>
                  <ButtonItem
                    horizontalAlignment='left'
                    colSpan={2}
                    buttonOptions={{
                      text: 'Add',
                      width: 120,
                      height: 36,
                      type: 'default',
                      onClick: () => {
                        modLookupData(1, lookupEntriesData);
                      },
                    }}
                  />
                  <ButtonItem
                    horizontalAlignment='left'
                    colSpan={2}
                    buttonOptions={{
                      text: 'Chg',
                      width: 120,
                      height: 36,
                      type: 'success',
                      onClick: () => {
                        modLookupData(2, lookupEntriesData);
                      },
                    }}
                  />
                  <ButtonItem
                    horizontalAlignment='left'
                    colSpan={2}
                    buttonOptions={{
                      text: 'Rem',
                      width: 120,
                      height: 36,
                      type: 'danger',
                      onClick: () => {
                        modLookupData(3, lookupEntriesData);
                      },
                    }}
                  />
                </Item>

                <Item>
                  <DataGrid
                    id='lptype-tab-entries-grid'
                    dataSource={stLpLookupDataEntries}
                    showRowLines={true}
                    showBorders={true}
                    showColumnLines={true}
                    columnAutoWidth={true}
                    defaultFocusedRowIndex={0}
                    focusedRowEnabled={true}
                    showColumnHeaders={false}
                    onFocusedRowChanged={e => {
                      setLookupEntriesGridData(e.row.data);
                    }}
                  >
                    <Column dataField='lp_type' visible={false} />
                    <Column dataField='lp_id' visible={true} width='100' />
                    <Column dataField='lp_value' visible={true} />
                    <Column dataField='short_id' visible={true} width='100' />
                  </DataGrid>
                </Item>
              </Form>
            </Item>
          </ResponsiveBox>
        </div>
      </Item>
    </ResponsiveBox>
  );
};

export default LookupTab;
